/*
 *   本文件为 模板打印路由
 *  tpl/ccsqdTpl
 */ 

const routeTpl = [
	{
		path: '/tpl',
		component:  () => import(/* webpackChunkName: "tpl" */ "@/views/tpl/CcsqdTpl"),
		redirect: '/tpl/ccsqdTpl' ,
		children: [
			{
				path: 'ccsqdTpl',
				name:'CcsqdTpl',
				component:  () => import(/* webpackChunkName: "tpl" */ "@/views/tpl/CcsqdTpl"),
				meta:{
					title:"出差申请单模板"
				}
			}	   
		]
	}
]

export default routeTpl