<template>
	<div>
		<!-- 待审批 -->
		<div v-if="appType == 1" >
			<el-input
			  type="textarea"
			  :rows="2"
			  placeholder="请输入审批意见"
			  v-model="appRemark">
			</el-input>
			<div style="padding: 20px;text-align: center;">
				<el-button type="primary" style="margin-right: 20px;width: 200px;height: 40px;" @click="approvalCaoZuo(1)">同 意</el-button>
				<el-button type="danger" style="width: 200px;height: 40px;" @click="approvalCaoZuo(2)">拒 绝</el-button>
			</div>
		</div>
		<!-- 已审批 -->
		<div v-if="appType == 2">
			
		</div>
		<!-- 已过期 -->
		<div v-if="appType ==3">
			
		</div>
		<!-- 原始订单 -->
		<div v-if="!appType || appType == 0">
			
			<slot name='orderFooterSlot'></slot>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				appRemark:'',
			}
		},
		props:{
			// 订单详情id
			orderId:{
				type:[String],
				default(){
					return ''
				}
			},
			// 工作项id
			workItemId:{
				type:[String],
				default(){
					return ''
				}
			},
			// 业务类型：11001、等
			orderType:{
				type:[String,Number],
				default(){
					return ''
				}
			},
			// 0 正常 1.待我审批 2.已审批 3.已过期
			appType:{
				type:[String,Number],
				default(){
					return 0
				}
			},
			// isShowApprove 是否显示审批
			isShowApprove:Boolean,
		
		},
		
		// 
		methods:{
			// 审批操作
			approvalCaoZuo(type){
				if(type == 2 && !this.appRemark){
					this.$alert('请填写审批拒绝原因！')	
					return false
				}
				let queryString = {};
					queryString = {
					    appRemark: this.appRemark,
					    completeState: type==1?'1':'0',
					    completeWay: 1,
					    orderId: this.orderId,
					    workItemId: this.workItemId,
					}
				this.$conn.getConn('commu.myAppDetail')(queryString,(res)=>{
					let tipsTitle = type==1?'审批成功':'审批拒绝';
					// 点击确定后，将关闭本页面！ // 暂不提示，需求变更，
					this.$alert('',tipsTitle ,{
					  confirmButtonText: '确定',
					  callback: action => {
						this.$emit('closePage',true)
					  }
					})
				},(err)=>{
					
					this.$alert(err.tips,'审批失败')
				})
			},
			
			
		}
		
		
	}
</script>

<style scoped="scoped" lang="scss">
	
</style>
