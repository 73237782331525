
/*
 *   本文件为 国内酒店路由
 *
 */ 

const routeHtCn = [
	{
		path: 'htCnRouter',
		component:  () => import( "@/views/htCn/HtCn"),
		redirect: 'htCnRouter/htCnQuery' ,
        children: [
			{
				path: 'htCnQuery',
				name:'HtCnQuery',
				component:  () => import( "@/views/htCn/HtCnQuery"),
				meta:{
					title:"酒店查询"
				}
			},
			{
				path: 'HtCnDetails',
				name:'HtCnDetails',
				component:  () => import( "@/views/htCn/HtCnDetails"),
				meta:{
					title:"酒店详情"
				}
			},
            {
				path: 'HtCnEdit',
				name:'HtCnEdit',
				component:  () => import( "@/views/htCn/HtCnEdit"),
				meta:{
					title:"酒店订单填写"
				}
			},
			// {	
			// 	path: 'htCnOrderDetail',
			// 	name:'HtCnOrderDetail',
			// 	component:  () => import(  "@/views/htCn/HtCnOrderDetail"),
			// },
            {	
				path: 'htRefundRooms',
				name:'HtRefundRooms',
				component:  () => import(  "@/views/htCn/HtRefundRooms"),
				meta:{
					title:"酒店退票"
				}
			},

				   
		]
	}
	
]

export default routeHtCn