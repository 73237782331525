import { htCnState } from './state.js'

const mutations={

    SAVE_NIGHT(state,num){  //存储酒店居住的晚数
        state.night=num
    },
    SAVE_PRICE(state,num){ //酒店价格
        state.price=num
    },
    SAVE_ROOMS(state,num){ //房间数
        state.homeRooms=num
    },
    SAVE_ROOMINFO(state,num){ //房间信息
        state.roomInfo=num
    },
    SAVE_HOTELINFO(state,num){ //房间信息
        state.hotelInfo=num
    },
    SAVE_SERVICE_PRICE(state,num){ //房间信息
        state.servicePrice=num
    },
    SAVE_DESCRIPTION(state,value){
        state.description=value
    },
    SET_HTCN_QUERY(state, obj) {// 设置酒店出差申请单   
		for(var key in obj) {
			state.htCnQuery[key] = obj[key];
		}
	},
    SET_HTCN_CCSQD(state,obj){
        state.htCnCcsqD=Object.assign({},state.htCnCcsqD,obj)
        // for(var key in obj) {
		// 	state.htCnCcsqD[key] = obj[key];
		// }
    },
    COPY_ROOMS(state,arr){
        let res=[]
        arr.forEach(element => {
             res.push(element)
        });
        state.cusRoomsState=res
    },
    	// 设置查询参数
	SET_HTCN_PARAMS(state, obj) {
		for(var key in obj) {
			state.htCnParams[key] = obj[key];
		}
	},
    SET_HTCN_SONNTAGS(state, obj) {     
		for(var key in obj) {
			state.htCnSonTags[key] = obj[key];
		}
	}, 
    DELETE_HTCN_SONNTAGS(state,obj,){ //删除单项
        for(let key in state.htCnSonTags) {
            if(key=Object.keys(obj)[0]){
                let index= state.htCnSonTags[key].indexOf(obj[Object.keys(obj)[0]])
                if(index!=-1){
                    state.htCnSonTags[key].splice(index,1)
                }
            }
		}
    }  ,
    SET_HTCN_POLICY(state,obj){
        state.htCnPolicy=obj
    },
	SET_CXR_LIST(state,arr){
        arr = arr || []

		state.cxrList = arr.map(cxrItem=>{
            return {
                ...cxrItem,
                empName:cxrItem.empName || cxrItem.cxrName || '',
            }
        });
	},
    SET_LSR_LIST(state,obj){
		state.lsrList = obj;
	},
    SAVE_NIGHT_ROOMS(state,arr){
        state.ninghtRomms=arr
    },
    SAVE_ISFREEMEAl(state,values){
        state.isFreeMeal=values
    },
	
	//初始化酒店缓存信息  退出时 使用
	initHtCnInfo(state, obj){
		let initState = htCnState();
		for(var key in initState){
			state[key] = initState[key];
		}
	}
}
export default mutations