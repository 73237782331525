/**
 * request.js
 * 通过promise对axios做二次封装，针对用户端参数，做灵活配置
 */
import { Message, Loading } from "element-ui";
import instance from "./interceptor";

/**
 * 核心函数，可通过它处理一切请求数据，并做横向扩展
 * @param {url} 请求地址
 * @param {params} 请求参数
 * @param {options} 请求配置，针对当前本次请求；
 * @param beforeFun   请求前
 * @param thenBefore  请求状态返回
 * @param loading 是否显示loading
 * @param mock 本次是否请求mock而非线上
 * @param error 本次是否显示错误
 * @param jsfile 本次是否响应值是js文件
 */
function request(
  url,
  params,
  options = {
    loading: false,
    mock: false,
    error: true,
    jsfile: false,
    noNeedToken: false,
    beforeFun: () => {},
    thenBefore: () => {},
  },
  method,
  successFn,
  errorFn
) {
  let loadingInstance;
  // 请求前loading
  if (typeof options.beforeFun == "function") {
    options.beforeFun && options.beforeFun();
  }
  if (options.loading) loadingInstance = Loading.service();
  return new Promise((resolve, reject) => {
    let data = {};
    // get请求使用params字段
    if (method == "get") data = { params };
    // post请求使用data字段
    if (method == "post") data = { data: params };
    // POST请求使用data字段，传值json对象处理
    if (method == "jsonp") {
      method = "post";
      let headers = options.headers || {};
      data = {
        data: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          ...headers,
        },
      };
    }
    // 通过mock平台可对局部接口进行mock设置
    // if(options.mock)url='http://www.mock.com/mock/xxxx/api';
    instance({
      url,
      noNeedToken: options.noNeedToken,
      timeout: options.timeout,
      method,
      ...data,
    })
      .then((res) => {
        if (typeof options.thenBefore == "function") {
          options.thenBefore && options.thenBefore();
        }
        // 此处作用很大，可以扩展很多功能。
        // 比如对接多个后台，数据结构不一致，可做接口适配器
        // 也可对返回日期/金额/数字等统一做集中处理
        if (res && res.httpStatus === 200) {
          successFn && successFn(res);
          resolve(res); //
        } else {
          // 如果返回的是js文件数据
          if (options.jsfile) {
            successFn && successFn(res.data);
            resolve(res.data); //
          } else {
            if (!res) {
              res = { code: "400", tips: "服务器内部错误" };
            }
            // 通过配置可关闭错误提示
            if (errorFn) {
              errorFn && errorFn(res);
            } else {
              if (options.error) {
                Message.error(res.tips);
              }
            }

            reject(res);
          }
        }
      })
      .catch((error) => {
        // Message.error(error)
      })
      .finally(() => {
        loadingInstance && loadingInstance.close();
      });
  });
}
// 封装GET请求  $url,param,funobj,successFn,errorFn
function get(url, params, options, successFn, errorFn) {
  return request(url, params, options, "get", successFn, errorFn);
}
// 封装POST请求
function post(url, params, options, successFn, errorFn) {
  return request(url, params, options, "post", successFn, errorFn);
}
// 封装post请求 接受方式json对象
function jsonp(url, params, options, successFn, errorFn) {
  return request(url, params, options, "jsonp", successFn, errorFn);
}

export default {
  get,
  post,
  jsonp,
};
