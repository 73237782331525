<template>
	<el-card class="box-card ct-main">
		<p class="text-left title-name">联系人</p>
		<div class="marbtm20 ct-sort" @click="contactData">
			<div class="ct-w text-left">
				<span class="ct-title">联系人姓名</span>
				<el-input  placeholder="请输入联系人姓名" v-model="contactObj.linkName"  class="martop10 text-color"></el-input>
			</div>
			<div class="ct-w text-left">
				<span class="ct-title">联系人电话</span>
				<el-input  placeholder="用于接收订票信息及航班实时信息" v-model="contactObj.linkPhone"  class="martop10  text-color"></el-input>
			</div>
		</div>
	</el-card>
</template>

<script>
	export default{
		name: "Comtacts",
		data() {
			return {
				contactObj:{
					linkName:'',
					linkPhone:'',
					linkEmail:'',
				}
				
			}
		},
		mounted() {
			let userInfo =JSON.parse(window.sessionStorage.getItem('user_info')) 
			let empInfo = userInfo.empInfo;
			this.contactObj.linkName = empInfo.empName || '';
			this.contactObj.linkPhone = empInfo.phoneNumber || '';
			this.contactObj.linkEmail = empInfo.linkEmail || '';
			this.contactData()
		},
		methods: {
			contactData(){
				this.$emit('contactInfo',this.contactObj)
			}
		}
	}
</script>

<style scoped lang="scss">
	.el-card.is-always-shadow{
		border: 1px solid #E6E6E6;
		box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.02);
		border-radius: 0;
	}
	.ct-main{
		font-family: '微软雅黑';
		.title-name{
			font-size: 24px;
			font-weight: bold;
			color: #223344;
			line-height: 24px;
			margin-bottom: 44px;
		}
		.ct-title{
			font-size: 14px;
			font-weight: 400;
			color: #748197;
			line-height: 15px;
		}
		/deep/ .el-card__body{
			padding:30px;
		}
		.text-left{
			text-align: left;
		}
		.text-color{
			font-size: 16px;
			font-weight: bold;
		}
		.martop20{
			margin-top: 20px;
		}
		.marbtm20{
			margin-bottom: 20px;
		}
		.martop10{
			margin-top: 10px;
		}
		.ct-sort{
			display: flex;
			justify-content: space-between;
			.ct-w{
				width: 46%;
			}
		}
		/deep/ .el-input__inner{
			border: none;
			border-radius: 0;
			border-bottom: 1px solid #DCDFE6;
			padding: 0;
		}
		/deep/ .el-input__inner::-webkit-input-placeholder {
		            color: #999999;
		            font-size: 15px;
					font-weight: bold;
		        }	
	}
	
</style>
