<template>
	<div>	
		<el-popover placement="bottom-start" width="500" popper-class="pop-car-city-subGroup" ref="popCity"
			@show="showPopverCity" @hide="hidePopverCity" v-model="cityVisible" :close-delay="0" trigger="click">
			<div slot="reference">
				<slot name="content"></slot>
			</div>
			<div v-if="!searchCityBool">
				<el-tabs type="border-card">
					<el-tab-pane v-for="(item, index) in carCityList" :key="index" :label="item.titleName=='HOT'?'热门':item.titleName">
						<div v-for="(cItem, cIndex) in item.content" :key="cIndex" style="width:100%;display: flex;">
							<div style="width: 5%;padding-top:6px;color:#0076F5;" v-if="cItem.name=='HOT'?false:true">{{cItem.name}}</div>
							<div :style="{width: cItem.name=='HOT'?100+'%':95+'%',}" style="display: flex;flex-wrap: wrap;">
								<div style="text-align: center;" v-for="(vItem, vIndex) in cItem.value" :key="vIndex">
									<el-tooltip class="item" effect="dark" :content="vItem.cityName" placement="top">
										<div slot="content">
											<span>
												<span>{{ vItem.cityName }}</span>
											</span>
										</div>
										<el-button
	
											style="border:none;width:70px;padding:10px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" @click="checkBtn(vItem)">
											{{ vItem.cityName }}
										</el-button>
									</el-tooltip>
								</div> 
							</div>
						</div>
					</el-tab-pane>
				
				</el-tabs>
			</div>
			<div v-else style="margin: 12px;">
				<div>可输入中文/全拼/简拼/城市名称</div>
				<div class="search-ht-city-result-content" v-if="searchCityListResultCurrent.length > 0">
					<p v-for="(item, index) in searchCityListResultCurrent" :key="index" :class="[
							'search-ht-city-result',
							index == 0 ? 'red' : '',  
						]" @click="checkBtnSearch(item)">
						<span>{{ item[1] }}</span>
						<span>{{ item[4] }}</span>
					</p>
					<yj-pagination :small="true" paStyle="margin: 20px 0;"
						@handleCurrentChange="handleCurrentChange" :total="searchCityListResult.length"
						:currentPage="currentPageCity"></yj-pagination>
				</div>
				<div class="search-ht-city-result-content" v-else>暂无数据</div>
			</div>
		</el-popover>
	</div>
</template>

<script>
	const isasc = /^[\!-~]+/ //是否为字母数字
	export default {
		name: 'CarCity', 
		props: {
			value:{
				type:[String,Number],
				default: '',
			},
			keywords: {
				type: String,
				default: () => {
					return ''
				}
			},
		},
		data() {
			return {
				currentPageCity: 1,		 // 当前页面
				activeIndex: -1,  // 选中城市地址
				activeIndexSub: -1, // 提示城市地址
				cityVisible: false, // 城市控件隐显
				inputCity: '', //输入框参数
				carCityList: [{  // 城市列表
						title: 'HOT',
						titleName: '热门',
						content: [],
					},
					{
						title: 'ABCDEF',
						titleName: 'ABCDEF',
						content: [],
					},
					{
						title: 'GHJ',
						titleName: 'GHJ',
						content: [],
					},
					{
						title: 'KLMN',
						titleName: 'KLMN',
						content: [],
					},
					{
						title: 'PQRSTW',
						titleName: 'PQRSTW',
						content: [],
					},
					{
						title: 'XYZ',
						titleName: 'XYZ',
						content: [],
					},
				],
				searchCityList: [], // 搜索城市列表数据
				searchCityListResult: [], // 查询后的城市数据
				searchCityListResultCurrent: [], // 查询后的城市数据分页
				searchCityBool: false, // 是否显示搜索数据
				timeStampCity: 0, //输入字符触发筛选时间
			}
		},

		watch:{
			value:{
				handler(val){
					if(val){
						this.inputCity = val;
					}
				},
				immediate: true,
				deep: true,	
			},
			keywords(val) {
				this.inputCity = val;
				this.inputCityEvent(val);
			}
		},
		mounted() {
			
			if(this.searchCityList.length==0){
				this.getCity()
			}
			let carCityList = this.$common.localget('carCityList');
			if (carCityList && carCityList.length > 0) {
				this.carCityList = carCityList
			} else {
				this.getCity()
			}
			
		},
		methods: {
			
			handleCurrentChange(val) {
				this.searchCityListResultCurrent = []
				this.searchCityListResult.map((v, i) => {
					if (i < val * 10 && i >= (val - 1) * 10) {
						this.searchCityListResultCurrent.push(v)
					}
				})
			},
			// 城市关键字查询
			inputCityEvent(e) {
				
				let term = e;
				if (!term) {
					let obj = {}
					this.$emit('checkCarCityList',obj,this.index)
					this.searchCityBool = false;
					return;
				}
				this.timeStampCity = 1; // 1s延时
				let chReg = /^[\u4e00-\u9fa5]+$/;
				let enReg = /^[a-zA-Z]+$/
				setTimeout(() => {
					if (this.timeStampCity - 1 === 0) {
						this.$refs.popCity.doShow();
						if (/^[0-9]/.test(term)) {
							this.searchCityListResult = [];
						}
						this.searchCityListResult = [];
				
						let chResolve = chReg.test(term);// 中文验证
						let enResolve = enReg.test(term);// 字母验证
						let searchCityList = this.searchCityList;
						for (var cityindex in searchCityList) {
							if (chResolve) {
								if (searchCityList[cityindex][1].indexOf(term) != -1) {
									this.searchCityListResult.push(searchCityList[cityindex]);
								}
							} else if (enResolve) {
								let mather = new RegExp("^" + term, "i");
								if (mather.test(searchCityList[cityindex][2]) || mather.test(searchCityList[cityindex][3])) {
									this.searchCityListResult.push(searchCityList[cityindex]);
								}
							} else {
								this.searchCityListResult = []
							}
				
						}
						this.searchCityBool = true;
						this.searchCityListResultCurrent = [];
						this.searchCityListResult.map((v, i) => {
							if (i < 10) {
								this.searchCityListResultCurrent.push(v);
							}
						});
					}
				}, 500);
			},
			// 内容移入事件
			enter(i, iSub) {
				this.activeIndex = i
				this.activeIndexSub = iSub
			},
			// 内容移出事件
			leave(i) {
				this.activeIndex = -1
				this.activeIndexSub = -1
			},
			// 获取城市数据
			getCity() {
				this.$conn.getConn('staticData.bcity', {
						jsfile: true
					})()
					.then((res) => {
						// 去掉县等没有必要展示的
						let myObj = res
						for (let i in myObj) {
							let myself = myObj[i].filter(e => {
								return e.cityType !== 4
							})
							myObj[i] = myself
						}

						let obj = myObj
						// 查询数据构成
						this.searchCityList = []
						let searchList = []
						for (let i in obj) {
							if (i != 'HOT') {
								searchList.push(...obj[i])
							}
						}
						searchList.map((item) => {
							let arr = [
								item['id'],
								item['cityName'],
								item['pyjsm'],
								item['fullspell'],
								item['province'],
							]
							this.searchCityList.push(arr)
						})
						// 点选数据构成
						this.carCityList.map((item, index) => {
							let arr = []
							if (index == 0) {
								arr.push({
									name: 'HOT',
									value: obj['HOT'],
								})
							} else {
								for (let key in obj) {
									if (item.title.indexOf(key) != -1) {
										let arrObj = {
											name: key,
											value: obj[key],
										}
										arr.push(arrObj)
									}
								}
							}
							this.$set(this.carCityList[index], 'content', arr)
							this.$common.localset('carCityList', this.carCityList)
						})
					})

			},

			// 内容点击事件
			checkBtn(val) {
				this.inputCity = val.cityName;
				this.$emit('checkCarCity', val);
				this.cityVisible = false;
			},
			// 关键字查询点击事件
			checkBtnSearch(val) {
				let obj = {
					id: val[0],
					cityName: val[1],
					pyjsm: val[2],
					fullspell: val[3],
					province: val[4],
				}
				this.inputCity = val[1];
				this.$emit('checkCarCity', obj)
				this.$refs.popCity.doClose()
			},
			// 监听弹框显示时的方法
			showPopverCity() {

				this.cityVisible = true;
			},
			// 监听弹框隐藏时的方法
			hidePopverCity() {
				// 处理
				this.searchCityBool = false
			},
			// 监听弹框显示时的方法
			showPopver() {

			},
			// 监听弹框隐藏时的方法
			hidePopver() {
				// 处理
			},
		},
	}
</script>

<style lang="scss">
	.el-popover.pop-car-city-subGroup{
		padding: 0;
		.car-content{
			width: 100%;
			display: flex;
			.car-content-left{
				width: 10%;
			}
			.car-content-right{
				width: 90%;
				.car-city-btn{
					margin-left: 0px;
					border: none;
				}
				.el-button{
					border: none;
				}
			}
		}
		
		.search-ht-city-result-content {
			:hover {
				background-color: #eee;
				cursor: pointer;
			}

			padding-top: 10px;
		}

		.scroll-geo {
			height: 400px;
			overflow-y: scroll;
		}

		.search-ht-city-result {
			display: flex;
			justify-content: space-between;
			padding: 10px;
		}

		.red {
			color: #f00;
		}
	}
</style>
