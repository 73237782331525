<template>
	<div style="position: relative;">
		<!-- 输入框显示  slot="reference"-->

		<!-- 弹框 -->
		<el-popover  placement="bottom-start" width="700" popper-class="pop-ht-city"
			ref="popTkCity" 
			@show="showPopverCity" 
			@hide="hidePopverCity" 
			v-model="popTkCityVisible" 
			:close-delay="0"
			transition="fade-in-linear"
			trigger="click" 
			
			>
			
			<!--@blur="inputBlur()" -->
			<!-- <i slot="prefix" class="el-input__icon el-icon-search"></i> -->
			<!-- <el-input size="large" style="width: 230px" placeholder="可输入城市或机场" v-model.lazy="inputCity"
				@input.native="inputCityEvent" @click="inputClick()" lot="reference"  >
			
			</el-input> -->
			<div slot="reference"  >
				<slot name="referenceContent" @click="inputClick()">
					
				</slot>
			</div>
			
			
			<!-- 弹窗内容显示 -->
			<div>
				<el-tabs :tab-position="tabPosition" @tab-click="changeInternational" v-model="interTabIndex">
					<el-tab-pane v-for="(tabItem,tabIndex) in tabList" :label="tabItem.titleName" :disabled="false"
						:key="tabIndex">
						<el-tabs type="card">
							<el-tab-pane v-for="(item, index) in tabItem.list" :key="index" :label="item.titleName">
								<div class="city-content">
									<div class="city-item" v-for="(cityValue,cityKey) in item.content" :key="cityKey">

										<el-row :gutter="24">
											<el-col :span="2" v-if="cityKey != 'HOT'">
												<el-button style="display: inline-block;border:none;">{{cityKey}}
												</el-button>
											</el-col>
											<el-col :span="22">
												<span v-for="(cityItem,cityIndex) in cityValue" :key="cityIndex">
													<el-tooltip placement="top-start" :open-delay="700">
														<div slot="content">
															<span>
																<span>{{cityItem.cityName}}</span>
																<span
																	v-show="cityItem.airportName">-{{cityItem.airportName}}</span>
																<span>({{cityItem.threeCode}})</span>
															</span>
														</div>
														<el-button
															style="width:92px;text-align : left;display: inline-block;border:none;"
															@click="confirmChoose(cityItem)">
															{{cityItem.cityName}}
														</el-button>
													</el-tooltip>

												</span>
											</el-col>
										</el-row>


									</div>

								</div>
							</el-tab-pane>
						</el-tabs>

					</el-tab-pane>


				</el-tabs>
			</div>

		</el-popover>

		<!-- 搜索弹窗  v-if="searchCityBool" -->

		<el-popover placement="bottom-start" width="400" popper-class="pop-ht-city"
			ref="popSearchTkCity"  
			@show="showPopverCity" 
			@hide="hidePopverSearchTkCity" 
			v-model="searchCityBool" 
			:close-delay="0"
			trigger="click"
			
			>
			<div>

				<el-table ref="singleTable" :data="serachList" highlight-current-row
					@current-change="handleCurrentChange" style="width: 100%" row-key="key" :show-header="false">
					<el-table-column align="left">
						<template slot-scope="scope">
							
								{{`${scope.row.cityName || ''} ${scope.row.airportName || ''} ${scope.row.threeCode || ''}`}}
							
						</template>
					</el-table-column>

				</el-table>
				<!-- 搜索分页信息 -->
				<yj-pagination v-if="serachList.length>0" :small="true" paStyle="margin: 20px 0 20px 0;" @handleCurrentChange="pageCurrentChange"
					:total="total" :currentPage="currentPage"></yj-pagination>
			</div>
		</el-popover>
	</div>
</template>

<script>
	export default {
		name: "TkCity",
		data() {
			return {
				inputCity: '',
				popTkCityVisible:false,
				visible: false,
				searchCityBool: false, // 当前是否搜索列表
				serachList: [],
				searchListCurrent: [], //分页当前操作
				currentPage: 1, // 当前页
				pageSize: 10,
				total:0,
				currentRow: null, // 选中的当前行

				tabPosition: 'left',
				interTabIndex: "0", // 0.国内、1.国际   tab索引
				// 侧边栏枚举
				tabList: [{
						title: "0",
						titleName: '国内',
						list: [{
								title: "HOT",
								titleName: "热门",
								content: {},
							},
							{
								title: "ABCDEF",
								titleName: "ABCDEF",
								content: {},
							},
							{
								title: "GHJ",
								titleName: "GHJ",
								content: {},
							},
							{
								title: "KLMN",
								titleName: "KLMN",
								content: {},
							},
							{
								title: "PQRSTW",
								titleName: "PQRSTW",
								content: {},
							},
							{
								title: "XYZ",
								titleName: "XYZ",
								content: {},
							},
						]
					},
					{
						title: "1",
						titleName: '国际及中国港澳台',
						list: [{
								title: "HOT",
								titleName: "热门",
								content: {},
							},
							{
								title: "ABCDEF",
								titleName: "ABCDEF",
								content: {},
							},
							{
								title: "GHJ",
								titleName: "GHJ",
								content: {},
							},
							{
								title: "KLMN",
								titleName: "KLMN",
								content: {},
							},
							{
								title: "PQRSTW",
								titleName: "PQRSTW",
								content: {},
							},
							{
								title: "XYZ",
								titleName: "XYZ",
								content: {},
							},
						]
					},

				],




			}
		},
		props:{
			keywords:{
				type:String,
				default:()=>{
					return ''
				}
			},
			tripType:{
				type:[String,Number],
				default:()=>{
					return '1'
				}
			},
			bookStyle:{
				type:[String,Number],
				default:()=>{
					return '1'
				}
			},
			// 是否受企业参数控制
			isCorpControl:Boolean,
			
		},
		computed: {
			changeRouteRole(){
				return this.$common.getVipParamterByParNo(this.$store,10991, 0);
			}
		},
		watch: {
			interTabIndex(val) {
				
			},
			keywords(val){
				
				this.inputCity = val;
				this.inputCityEvent(val);
			}
			
		},
		mounted() {
			this.getCityData();
		},
		methods: {
			// 搜索框搜索
			inputCityEvent(e) {
				
				let term = e;
				// let term = e.target.value;
				
				if (!term) {
					this.searchCityBool = false;
					this.popTkCityVisible = true;
					return;
				}
				this.inputCity = term;
				this.popTkCityVisible = false;
				this.searchCityBool = true;
				// 机票城市搜索
				this.$common.debounce2(this.getSearchTkCityList(1), 1000)

			},
			// 点击输入框
			inputClick(){
				// 若当前不显示搜索框， 点击时，显示多城市弹窗
				if(!this.searchCityBool){
					this.popTkCityVisible = true;
				}
			},
			// 失去焦点
			inputBlur(){
				this.searchCityBool = false;
				this.popTkCityVisible = false;
			},
			
			// 机票城市搜索
			getSearchTkCityList(pageNum) {
				
				this.$conn.getConn("staticData.getGjAirportData",{loading:false})({
						keywords: this.inputCity,
						count:this.pageSize,
						pageNum:this.currentPage
					},(res)=>{
						let data = res.data || [];
						let cityList = data.list || [];
						this.total = data.total;
						cityList = cityList.map((item, index) => {
							item.key = `${item.cityName }-${item.airportName }-${item.threeCode }`
							return item
						})
						this.serachList = cityList;
						this.searchCityBool = true;
						this.popTkCityVisible = false;
					},(err)=>{
						
					})
			},
			// 选中的当前行
			handleCurrentChange(val) {
				this.currentRow = val;
				this.confirmChoose(val)
			},

			// 分页切换  切换页码
			pageCurrentChange(pageNum) {
				this.getSearchTkCityList(pageNum) 
			},
			// 确定选择
			confirmChoose(item) {
			
				item.cityInput= (item.cityName || '') + (item.airportName?`(${(item.airportName || '')})`:'') +  (item.threeCode?`(${(item.threeCode || '')})`:"");
				this.$emit("chooseCity", item);
				this.popTkCityVisible = false;
				this.searchCityBool = false;
			},

			// 监听弹框显示时的方法
			showPopverCity() {
				
				// 当城市被禁用的时候应该无法触发弹窗！
				// 什么情况下城市选择器无法出来？
				// 因公模式且为出差申请单模式下且changeRouteRole!=1的情况 ，changeRouteRole为2或3的时候才会触发有弹窗的出现！
				if(this.isCorpControl && this.tripType==1 && this.bookStyle==2 ){
					if(this.changeRouteRole==0){
						this.popTkCityVisible  = false;
						return 
					}else if( this.changeRouteRole==1){
						// this.popTkCityVisible = true;
					}else if(this.changeRouteRole==2 || this.changeRouteRole==3){
						this.popTkCityVisible = false;
						this.$emit("changeRouteRole2");
						return 
					}
				}
				// 如果没有搜索弹窗，则弹出这个
				if(!this.searchCityBool){
					this.popTkCityVisible = true;
				}
				
			},
			// 监听弹框隐藏时的方法
			hidePopverCity() {
			
				this.searchCityBool = false;
			},
			
			hidePopverSearchTkCity(){
				// this.searchCityBool = false;
			},
			//  tab 切换
			changeInternational(item) {

				if (item.index == 1) {
					this.getTkInteCity();
				}
			},

			// 获取城市数据
			getCityData() {
				// 获取国内热门城市
				if (this.interTabIndex != '1') {
					this.getTkCity();
				} else { // 获取国际热门城市
					this.getTkInteCity();
				}

			},
			// 获取国内数据
			getTkCity() {
				
				var tkHotCityList =  this.$common.localget('TkHotCityList') || [];
				if(tkHotCityList.length == 0){
					// 热门城市
					this.$conn.getConn("staticData.city", {
							jsfile: true
						})({},(res)=>{
							let obj = res || {};
							tkHotCityList = obj['HOT'] || [];
							this.$common.localset('TkHotCityList',tkHotCityList );
							this.setTkHotCityList(tkHotCityList);
							
						},(err)=>{
							
						});
				}else{
					this.setTkHotCityList(tkHotCityList);
				}
				
				// // a-z城市
				var TkAZCityList =  this.$common.localget('TkAZCityList') || {};
				if(Object.keys(TkAZCityList).length == 0){
					this.$conn.getConn("staticData.cityAirportForPx", {
							jsfile: true
						})({},(res)=>{
							let obj = res || {};
							TkAZCityList = obj;
							this.setTkAZCityList(TkAZCityList)
							this.$common.localset('TkAZCityList',TkAZCityList);
							
						},(err)=>{
							
						});
				}else{
					this.setTkAZCityList(TkAZCityList)
				}
				
			},
			// 设置国内机票热门城市数据
			setTkHotCityList(cityList){
				for (let k = 0; k < this.tabList[0].list.length; k++) {
					let cityItem = this.tabList[0].list[k] || {};
					if (cityItem.title == 'HOT') {
						this.tabList[0].list[k].content['HOT'] = cityList || [];
						break;
					}
				}
			},
			// 设置国内机票A-Z机场城市数据
			setTkAZCityList(cityMap){
				for (let i in cityMap) {
					for (let k = 0; k < this.tabList[0].list.length; k++) {
						let cityItem = this.tabList[0].list[k] || {};
						if (cityItem.title != 'HOT' && cityItem.title.indexOf(i) > -1) {
							this.tabList[0].list[k].content[i] = cityMap[i] || [];
						}
					}
				}
			},
			
			// 获取国际机票  热门城市 默认取前32个
			getTkInteCity() {
				var TkInteHotCityList =  this.$common.localget('TkInteHotCityList') || [];
				if(TkInteHotCityList.length == 0){
					this.$conn.getConn("staticData.getGjCityData")({
							count: 32
						},(res)=>{
							let data = res.data || [];
							TkInteHotCityList = data.list || [];
							this.setTkInteHotCityList(TkInteHotCityList)
							this.$common.localset('TkInteHotCityList',TkInteHotCityList);
						},(err)=>{
							
						})
				}else{
					this.setTkInteHotCityList(TkInteHotCityList)
				}
					
			},
			// 设置国际城市列表
			setTkInteHotCityList(cityList){
				for (let k = 0; k < this.tabList[1].list.length; k++) {
					let cityItem = this.tabList[1].list[k] || {};
					if (cityItem.title == 'HOT') {
						this.$set(this.tabList[1].list[k].content, 'HOT', cityList);
					}
				}
			},

		}
	}
</script>

<style>
</style>
