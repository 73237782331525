import commonMap from "./commonMap.js";
import common from '@/plugins/common.js'
const mutations = {
	// 设置企业参数
	setVipParamterList(state,obj){
		state.vipParamterList= obj || {};
	},
	// 企业模块参数
	setCorpMdParams(state, obj){
		for(var key in obj){
			state.corpMdParams = obj[key];
		}	
	},
	// 出差项目数据
	setCcProjectData(state, obj){
		for(var key in obj){
			state.ccProjectData = obj[key];
		}
	},
	// 成本中心
	setCostCenterData(state, obj){
		for(var key in obj){
			state.costCenterData = obj[key];
		}
	},
	// 出差申请单信息
	setCcsqdInfo(state, obj) {
		for(var key in obj){
			state.ccsqdInfo = obj[key]
		}
		
	},
	// 头像
	setHeadImg(state, obj){
		state.headImg = obj
	},
	// 差旅标准使用人
	setUseNameclbz(state, obj){
		for(var key in obj){
			state.useNameclbz = obj[key];
		}
	},
	
	//公共方法 初始化
	setCommonInitial(state, obj){
		//清除火车票缓存信息  退出时 使用
		let commonMapCopy = common.deepCopy(commonMap)
		for(var key in commonMapCopy){
			state[key] = commonMapCopy[key];
		}	
	}
}
export default mutations