<template>
  <div>
    <!-- 国内机票、国际机票-->
    <el-popover
      v-if="type == 1"
      placement="bottom-start"
      width="500"
      popper-class="pop-ht-city"
      ref="popCity"
      @show="showPopverCity"
      @hide="hidePopverCity"
      v-model="visible"
      :close-delay="0"
      trigger="click"
    >
	<!-- 正常弹窗显示 -->
      <div v-if="!searchCityBool">
        <el-tabs type="border-card">
          <el-tab-pane
            v-for="(item, index) in htCityList"
            :key="index"
            :label="item.titleName"
          >
            <div v-if="item.content.length > 0">
              <div class="city-ht">
                <div
                  class="city-ht-item"
                  v-for="(v, i) in item.content"
                  :key="i"
                >
                  <div
                    v-show="index != 0"
                    style="margin-right: 10px; width: 30px; color: red"
                  >
                    {{ v.name }}
                  </div>
                  <div>
                    <span
                      style="position: relative"
                      v-for="(vSub, iSub) in v.value"
                      :key="iSub"
                      @mouseenter="enter(i, iSub)"
                      @mouseleave="leave(i, iSub)"
                      @click="checkBtn(vSub, type)"
                    >
                      <span class="city-ht-btn">
                        {{ vSub.cityName }}
                        <span
                          v-show="activeIndexSub == iSub && activeIndex == i"
                          style="
                            position: absolute;
                            top: 15px;
                            left: 0;
                            border: 1px solid #eee;
                            background-color: #ccc;
                            z-index: 8885;
                            padding: 5px 10px;
                            border-radius: 5px;
                            box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
                          "
                          >{{ vSub.cityName }}</span
                        >
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>暂无数据</div>
          </el-tab-pane>
        </el-tabs>
      </div>
	  <!-- 搜索框展示 -->
      <div v-else>
        <!-- <div>可输入中文/全拼/简拼/城市名称</div> -->
        <div
          class="search-ht-city-result-content"
          v-if="searchCityListResultCurrent.length > 0"
        >
          <p
            v-for="(item, index) in searchCityListResultCurrent"
            :key="index"
            :class="['search-ht-city-result', index == 0 ? 'red' : '']"
            @click="checkBtnSearch(item, type)"
          >
            <span>{{ item[1] }}</span>
            <span>{{ item[4] }}</span>
          </p>
          <yj-pagination
            :small="true"
            paStyle="margin: 20px 0 50px 0;"
            @handleCurrentChange="handleCurrentChange"
            :total="searchCityListResult.length"
            :currentPage="currentPageCity"
          ></yj-pagination>
        </div>
        <div class="search-ht-city-result-content" v-else>暂无数据</div>
      </div>
      <el-input
        size="large"
        style="width: 200px"
        placeholder="可输入中文/全拼/简拼/城市名称"
        v-model.lazy="inputCity"
        @input.native="inputCityEvent"
        slot="reference"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
    </el-popover>

    
  </div>
</template>

<script>
const isasc = /^[\!-~]+/; //是否为字母数字
export default {
  name: "TkCitySub",
  props: {
    // 1 国内城市 2 商圈
    type: {
      type: Number,
      default: 1,
      required: true,
    },
    cityId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentPageCity: 1,
      activeIndex: -1,
      activeIndexSub: -1,
      visible: false,
      inputCity: "",
      inputCityGeo: "",
      geoLocationList: [],
      htCityList: [
        {
          title: "HOT",
          titleName: "热门",
          content: [],
        },
        {
          title: "ABCDEF",
          titleName: "ABCDEF",
          content: [],
        },
        {
          title: "GHJ",
          titleName: "GHJ",
          content: [],
        },
        {
          title: "KLMN",
          titleName: "KLMN",
          content: [],
        },
        {
          title: "PQRSTW",
          titleName: "PQRSTW",
          content: [],
        },
        {
          title: "XYZ",
          titleName: "XYZ",
          content: [],
        },
      ],
      searchCityList: [],
      searchCityListResult: [], // 查询后的城市数据
      searchCityListResultCurrent: [], // 查询后的城市数据分页
      searchCityBool: false,
      searchCityGeoBool: false,
      timeStampCity: 0,
      timeStampCityGeo: 0,
      searchCityGeoListResult: [], // 查询后的商圈数据
    };
  },
  mounted() {
    if (this.type == 1) {
      this.getCity();
    }
  },
  watch: {
    cityId(n, v) {
      if (n) {
        this.getCityGeo();
      }
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.searchCityListResultCurrent = [];
      this.searchCityListResult.map((v, i) => {
        if (i < val * 10 && i >= (val - 1) * 10) {
          this.searchCityListResultCurrent.push(v);
        }
      });
    },
    // 商圈关键字查询
    inputCityGeoEvent(e) {
      let term = e.target.value;
      if (!term) {
        this.searchCityGeoBool = false;
        return;
      }
      this.timeStampCityGeo = e.timeStamp;
      setTimeout(() => {
        if (this.timeStampCityGeo - e.timeStamp === 0) {
          this.$refs.popCityGeo.doShow();
          this.$conn
            .getConn("staticData.poiPlace", { jsfile: true })({
              cityId: this.cityId,
              cityName: this.inputCity,
              keywords: term,
              cplx: 10903,
            })
            .then((res) => {
              if (res) {
                this.searchCityGeoListResult = res;
              } else {
                this.searchCityGeoListResult = [];
              }
              this.searchCityGeoBool = true;
            });
        }
      }, 500);
    },
    // 城市关键字查询
    inputCityEvent(e) {
      let term = e.target.value;
      if (!term) {
        this.searchCityBool = false;
        return;
      }
      this.timeStampCity = e.timeStamp;
      setTimeout(() => {
        if (this.timeStampCity - e.timeStamp === 0) {
          this.$refs.popCity.doShow();
          if (/^[0-9]/.test(term)) {
            this.searchCityListResult = [];
          }
          var useasc = isasc.test(term);
          this.searchCityListResult = [];
          let mather = new RegExp("^" + term, "i");
          for (var cityindex in this.searchCityList) {
            var city = this.searchCityList[cityindex];
            if (useasc) {
              //是拼音开头
              if (mather.test(city[3]) || mather.test(city[2])) {
                this.searchCityListResult.push(city);
              }
            } else {
              if (mather.test(city[1])) {
                this.searchCityListResult.push(city);
              }
            }
          }
          this.searchCityBool = true;
          this.searchCityListResultCurrent = [];
          this.searchCityListResult.map((v, i) => {
            if (i < 10) {
              this.searchCityListResultCurrent.push(v);
            }
          });
        }
      }, 500);
    },
    // 内容移入事件
    enter(i, iSub) {
      this.activeIndex = i;
      this.activeIndexSub = iSub;
    },
    // 内容移出事件
    leave(i) {
      this.activeIndex = -1;
      this.activeIndexSub = -1;
    },
    // 获取城市数据
    getCity() {
      this.$conn
        .getConn("staticData.bcity", { jsfile: true })()
        .then((res) => {
          let obj = res;
          // 查询数据构成
          this.searchCityList = [];
          let searchList = [];
          for (let i in obj) {
            if (i != "HOT") {
              searchList.push(...obj[i]);
            }
          }
          searchList.map((item) => {
            let arr = [
              item["id"],
              item["cityName"],
              item["pyjsm"],
              item["fullspell"],
              item["province"],
            ];
            this.searchCityList.push(arr);
          });
          // 点选数据构成
          this.htCityList.map((item, index) => {
            let arr = [];
            if (index == 0) {
              arr.push({
                name: "HOT",
                value: obj["HOT"],
              });
            } else {
              for (let key in obj) {
                if (item.title.indexOf(key) != -1) {
                  let arrObj = {
                    name: key,
                    value: obj[key],
                  };
                  arr.push(arrObj);
                }
              }
            }
            this.$set(this.htCityList[index], "content", arr);
          });
        });
    },
    // 获取商圈数据
    getCityGeo() {
      this.$conn
        .getConn("staticData.geoLocation", { jsfile: true })({
          cityId: this.cityId,
          type: "1,2",
        })
        .then((res) => {
          if (res) {
            this.geoLocationList = res;
            this.geoLocationList.map((item) => {
              if (item.type == "1") {
                item.typeName = "行政区";
              } else if (item.type == "2") {
                item.typeName = "商圈";
              }
            });
          } else {
            this.geoLocationList = [];
          }
        });
    },
    // 内容点击事件
    checkBtn(val, type) {
      this.visible = false;
      // 1 城市  2 商圈
      if (type == 1) {
        this.inputCity = val.cityName;
        this.$emit("checkHtCity", val);
      } else {
        this.inputCityGeo = val.name;
        this.$emit("checkHtCityGeo", val);
      }
    },
    // 关键字查询点击事件
    checkBtnSearch(val, type) {
      if (type == 1) {
        let obj = {
          id: val[0],
          cityName: val[1],
          pyjsm: val[2],
          fullspell: val[3],
          province: val[4],
        };
        this.inputCity = obj.cityName;
        this.$emit("checkHtCity", obj);
        this.$refs.popCity.doClose();
      } else {
        this.inputCityGeo = val.name;
        this.$emit("checkHtCityGeo", val);
        this.$refs.popCityGeo.doClose();
      }
    },
    // 监听弹框显示时的方法
    showPopverCity() {
      // 处理
    },
    // 监听弹框隐藏时的方法
    hidePopverCity() {
      // 处理
      this.searchCityBool = false;
    },
    // 监听弹框显示时的方法
    showPopver() {
      // 处理
      if (!this.cityId && this.type == 2) {
        this.$refs.popCityGeo.doClose();
        this.$message({
          type: "error",
          message: "请先选择城市",
        });
      }
    },
    // 监听弹框隐藏时的方法
    hidePopver() {
      // 处理
     
    },
  },
};
</script>

<style scoped lang="scss">
</style>
