<template>
	<div class="is-main">
		<el-card>
			<div class="is-bg">
				<h1 class="text-l">{{bxTitle}}</h1>
			</div>
			<div class="is-wis">
				<div v-for="(item, index) in inSuranceList" :key="item.bxid" class="is-is">
					<div class="is-bx-box" @click="chooseBxcp(item, index)" :class="{'is-bx-chooseBox':item.checked}">
						<span class="iconfont yj-icon-24gf-shield" :class="{isIcons:!item.checked,}" v-if="!item.checked"></span>
						<span class="iconfont yj-icon-24gf-shieldCheck" :class="{'isIcon':item.checked}" v-if="item.checked"></span>
						<span style="padding-left: 15px;">{{item.bxxzmc}}</span>
						<span style="padding-left: 15px;">
						<span style="color:#FFB31E;">{{'￥'+ item.saleprice}}</span>/人次</span>
					</div>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	export default {
		name: 'InSurance',
		data() {
			return {
				inSuranceList:[], // 保险产品列表
				chooseIdList:[], // 所有选中保险
				defaultBxListCopy:[],
			}
		},
		props:{
			cplx:{
				type:[Number,String],
				default:10901,
			},
			// 默认选中保险
			defaultBxList:{
				type: [Array],
				default(){
					return []
				}
			},
			bxTitle:{
				type: String,
				default:'您的航程已经获保障',
			},
			tripType:{
				type:Number,
				default(){
					return 3
				}
			}
			
		},
		watch:{
			defaultBxList:{
				immediate:true,
				deep:true,
				handler(val){
					this.defaultBxListCopy = this.$common.deepCopy(val)
				}
			}
		},
		mounted() {
			
			this.queryBxcpList();
		},
		methods: {
			setChooseBxList(){
				
			},
			queryBxcpList(){
				let queryString = {
					cplx: this.cplx,
					tripType:this.tripType,
				}
				this.$conn.getConn('insurance.getBxcpList')(queryString).then((res)=>{
					let data = res.data || [];
					this.inSuranceList = data.map((item,index)=>{
						
						// 如果已经选择保险
						if(this.defaultBxListCopy.length>0){
							let ppBxItemArray = this.defaultBxListCopy.filter((defItem)=>{
								return defItem.bxid  == item.bxid
							}) || []
							if(ppBxItemArray.length>0){
								item.checked = true
							}else{
								item.checked = false
							}
							
						}else{
							//强制绑定类型：0不绑定 1强制绑定 2默认选中，用户可取消 ,
							if(item.qzbd == '1' || item.qzbd == '2'){
								item.checked = true;
							}
						}
						
							
						return item
					})
					
					// 如果已经选中的报险，默认传回
					let chooseBxList =	this.inSuranceList.filter((item)=>{
						return item.checked
					}) || []
					this.$emit('chooseBxcp',chooseBxList);
					if(this.inSuranceList.length==0){
						this.$emit('isShowBX',false)
					}	
					
				}).catch((err)=>{
					this.inSuranceList = [];
					if(this.inSuranceList.length==0){
						this.$emit('isShowBX',false)
					}	
				})
				
			},

			// 选中保险产品
			chooseBxcp(bxcpItem,index){
				
				//强制绑定类型：0不绑定 1强制绑定 2默认选中，用户可取消 ,
				if(bxcpItem.qzbd != '1'){
					this.$set(this.inSuranceList[index], 'checked' , !bxcpItem.checked)
					
					let chooseBxList =	this.inSuranceList.filter((item)=>{
						return item.checked
					}) || []
					
					this.$emit('chooseBxcp',chooseBxList)
				}else{
					this.$set(this.inSuranceList[index], 'checked' , true)
					this.$message({
					          message: '当前保险为贵企业设置固定报险，不可取消！',
					          type: 'warning'
					        });
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.is-main{
		font-family: '微软雅黑';
		margin-top: 20px;
		.el-card.is-always-shadow{
			// border: 1px solid #E6E6E6;
			// box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.02);
			// border-radius: 5px 5px 0px 0px;
		}
		
		/deep/ .el-card__body{
			padding:0;
		}
		.is-bg{
			height: 90px;
			background: #E6F2FF;
			border-radius: 5px 5px 0px 0px;
			text-align: left;
			display: flex;
			justify-content: space-between;
			.text-l{
				font-size: 24px;
				font-weight: bold;
				color: #223344;
				line-height: 33px;
				padding:30px;
			}
			
		}
		.is-wis{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding:30px 30px 10px 30px;
			.is-is{
				width: 48%;
				margin-bottom: 30px;
				overflow: hidden;
				.is-bx-box{
					padding: 10px 20px;
					background: #F7F7F7;
					border-radius: 20px;
					box-sizing: border-box;
					border: 1px solid #fff;
					text-align: left;
					.isIcon{
						color: #006BB9;
					}
					.isIcons{
						color: #C4CDDD;
					}
				}
				.is-bx-chooseBox{
					background: #E6F2FF;
					border: 1px solid #006BB9;
					transition: all 0.5s ease;
					
				}
				.is-bx-box:hover{
					cursor: pointer;
				}
			}
		}
	}
</style>
