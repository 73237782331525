<template>
	<div>
		<div class="header-wrapper" :style="bgClass">
			<div class="header" > 
				<yj-nav-logo></yj-nav-logo>
				<div class="order-progress">
					<slot name="contentSlot"></slot>
				</div>
				
					
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		name:'NavDetail',
		props:{
			bgClass:{
				type:Object,
				default(){
					return{}
				}
			}
		},
		data() {
			return {
				
			}
		},
		mounted() {
		
			
		},
		methods: {

		}
	}
</script>

<style scoped lang="scss">
	.header-wrapper{
		    width: 100%;
		    height: 80px;
		    background: #fff;
		    border-bottom: 1px solid #d6dde2
	}
	.header{
		    width: 1200px;
		    margin: 0 auto;
		    // padding-left: -webkit-calc(100vw - 100%);
		    // padding-left: -moz-calc(100vw - 100%);
		    // padding-left: calc(100vw - 100%);
			position: relative;
			text-align: left;
			// padding-left: 210px;
	}
	

	.order-progress{
		padding-left: 20px;
		float: left;
		width: 990px;
		// width: calc(100% - 210px);
		display: inline-block;
	}

</style>
