<template>
	<div  class="cardText">
		<!-- <div class="sprList">
			<div class="spr-header">
				<div class="sprl1">审批顺序</div>
				<div class="sprl1 borderLeft">审批人</div>
				<div class="sprl1 borderLeft">审批部门</div>
			</div>
			<div v-for="item in applyData.gznr" :key="item.level">
				<div v-for="spItem in item.empList" :key="spItem.empId" class="spr-con">
					<div class="sprConl1">{{item.level}}</div>
					<div class="sprConl1 borderLeft">{{spItem.empNo}}{{spItem.empName}}</div>
					<div class="sprConl1 borderLeft">{{spItem.vipdeptName}}</div>
				</div>
			</div>
		</div> -->
		
		<el-steps direction="vertical" :active="applyList.length" :style="{'min-height': 60*applyList.length +'px',}">
			<el-step v-for="(item, index) in applyList" :key="index" 
				:title="'未开始'"
				:status="'wait'">
				<div slot="description">
					<div v-for="(spItem, i) in item.empList" :key="spItem.empId+''+i" >
						
						<div class="sprConl1 borderLeft">
							<span>{{spItem.ssr==1?'送审人':'审批人'}}：{{spItem.empNo}}{{spItem.empName}}</span>
							<span style="padding-left: 20px;">审批部门：{{spItem.vipdeptName}}</span>		
						</div>
					</div>
				</div>	
			</el-step>
		</el-steps>
	</div>
</template>

<script>
	export default{
		name: 'ApprovalRuleDetail',
		data() {
			return {
				applyData:{}, //审批人数据
				info:{}, // 详情信息
				isSend: true, //是否送审过
				applyList:[],
			}
		},
		props: {
			//详情 ，包含审批规则id 
			detail:{
				type: Object,
				default() {
					return {}
				}
			},
			// 审批规则id
			appId:{
				type: String,
				default:'',
			},
			// 注入送审人 想展示送审人
			sendPerson:{
				type: Object,
				default() {
					return {}
				}
			}
		},
		// mounted(){
		// 	this.info = this.detail || {};
		// 	if(this.appId){
		// 		this.appRulesSpgz(this.appId);
		// 	}
		// },
		watch:{
			appId:{
				handler(id){
					if(id){
						this.info = this.detail || {};
						this.appRulesSpgz(id);
					}
				},
				immediate: true,
			}
		},
		methods:{
			// 审批人信息
			appRulesSpgz(appId){
				let params = {
					spgzId: appId
				}
				this.$conn.getConn('commu.appRulesDetail')(params,(res)=>{
					let data = res.data || {};
					this.applyData = data;
					this.applyList = data.gznr || [];
					// 新建 出差申请单  添加送审人展示
					if(JSON.stringify(this.sendPerson)!="{}"){
						if(this.sendPerson.empList.length == 1){
							// 防止ie 此处不用 unshift()
							let list = [];
							list.push(this.sendPerson);
							let newList = [].concat(list,data.gznr);
							this.applyList = newList;
						}
					}
					// 审批人层级列表
					this.$emit('gznrList',res.data.gznr)
				},(err)=>{
					
				})
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.el-card.is-always-shadow{
		box-shadow: 1px 2px 2px 0px rgba(13, 2, 0, 0.02);
		border-radius: 5px ;
	}
	.cardText{
		font-family: '微软雅黑';
		width: 100%;
		.title{
			font-size: 24px;
			font-weight: 600;
			line-height: 22px;
			text-align: left;    
		}
		.sprList{
			margin-top: 38px;
			border: 1px solid #CCCCCC;
			.spr-header{
				display: flex;
				background-color: #F5F5F5;
				line-height: 45px;
				height: 45px;
				color: #808080;
				font-weight: bold;
				.sprl1{
					width: 33.33%;
					text-align: center;
				}
				.borderLeft{
					border-left: 1px solid #CCCCCC;
				}
				
			}
			.spr-con{
				display: flex;
				line-height: 45px;
				height: 45px;
				font-size: 12px;
				border-top: 1px solid #CCCCCC;
				background-color: rgba(240,240,240,0.1);
				.sprConl1{
					text-align: center;
				}
				.borderLeft{
					border-left: 1px solid #CCCCCC;
				}
			}
			
		}
		.el-button{
			margin-top: 30px;
			width: 100px;
			height: 40px;
		}
	}
</style>
