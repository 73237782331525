
/*
 *   本文件为 国际机票需求单路由
 *
 */ 

const routeInTk = [
	{
		path: 'inTkRouter',
		component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/international/interTk/InTk"),
		redirect: 'inTkRouter/InTkEdit' ,
		children: [
			{   
				path: 'inTkEdit',
				name:'InTkEdit',
				component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/international/interTk/InTkEdit"),
				meta:{
					title:"国际机票需求单"
				}
			},	   
		]
	},
	
	
]

export default routeInTk