<template>
	<div id="app"  >
	
		<router-view />
	</div>
</template>
<script>
	
	
	export default {
		data() {
			return {
			
			
			};
		},
		
		
	};
</script>

<style lang="scss">
	@import './scss/common.scss';
	
	* {
		padding: 0;
		margin: 0;
		box-sizing:border-box;
	}

	html,
	body {
		height: 100%;
		font-size: 14px;
		color: #424242;
		background-color: transparent;
		// -moz-user-select:none; /* Firefox私有属性 */
		// -webkit-user-select:none; /* WebKit内核私有属性 */
		// -ms-user-select:none; /* IE私有属性(IE10及以后) */
		// -khtml-user-select:none; /* KHTML内核私有属性 */
		// -o-user-select:none; /* Opera私有属性 */
		// user-select:none; /* CSS3属性 */
	}

	#app {
		font-family: '微软雅黑';
		// font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #424242;
		height: 100%;
	}
	
	// 总页面宽度
	.yj-zong-width{
		width: 100%;
	}
	// 主页面宽度
	.yj-main-width{
		width: 1200px;
	}
	// 主页面左侧
	.yj-main-width-left{
		width: 240px;
	}
	.yj-main-width-right{
		width: 940px;
	}
	.theme-index {
		z-index: 10000 !important;
	}

	.notify_class {
		z-index: 30000 !important;
	}
	

	
</style>
