<template>
	<div class="pp-main">
		<div class="pp-title" >
			<span  
				v-for="item in statusList" 
				:key="item.status" 
				:style="currentProgress()" 
				:class="{colorStatus:(currentStatus>=item.status)}"
			>
				{{item.name}}
			</span>	
		</div>
	
		<el-progress 
		   :text-inside="true" 
		   :percentage="percentNum" 
		   :show-text="false" 
		   :stroke-width="6" 
		   class="pp-con"
		   ></el-progress>
		
	</div>
</template>

<script>
	export default{
		name: 'ProgressEdit',
		props:{
			currentStatus:{  //传入的进度状态
				type:[Number,String],
				default:0,
			},
			statusList:{
				type: Array,
				default(){
					return []
				}
			}
		},
		
		data() {
			return {
				
			}
		},
		mounted(){
			
			
		},
		computed:{
			// 进度条百分比
			percentNum(){
				let num = 0;
				let w =this.getPercentNum();
				num = (w*this.currentStatus)
				return num
			}
		},
		methods: {
			// 获取当前状态百分比
			getPercentNum(){
				let w = 100;
				let len= this.statusList.length;
				if(len>0){
					w = 100/len;
				}
				
				return w
			},
			// 获取当前样式
			currentProgress(){
					let w =this.getPercentNum();
					var styleObj =  {
					  'width': w+'%',
					  'text-align':'right',
					 }
					return styleObj
				
			},
			
			
		},
	}
</script>

<style scoped lang="scss">
	.pp-main{
		margin-top:10px;
		.pp-title{
			display: flex;
			justify-content: flex-end;
		}
		.pp-con{
			padding:2px;
			background: #EBEEF5;
			border-radius: 10px;
			margin-top:10px;
		}
		.colorStatus{
			color: #006BB9;
		}
             /deep/ .el-progress-bar__outer{
            background-color: #E6E6E6;
        }
	}
</style>
