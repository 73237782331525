<template>
	<div class="gs-text" :style="calcStyle()">
		<el-radio v-model="TripTypeGS" label='1' @click.native="changeTripType($event,1)" :disabled="tripTypeGong == 1" v-if="tripTypeGong != 2">因公</el-radio>
		<el-radio v-model="TripTypeGS" label='2' @click.native="changeTripType($event,2)" :disabled="tripTypeSi == 1" v-if="tripTypeSi != 2">因私</el-radio>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	export default{
		name: 'TripType',
		props:{
			//模块是否开启处理 0-不开启 1-因私 2-因公 3-都开启
			vipParams:{
				type: [Number,String],
				default() {
					return 3
				}
			},
			// 注入的当前因公、因私
			tripType:{
				type:[Number,String],
				default: 1 
			},
			block:{
			  type:[String],
			  default:'inline-block'
			}
			
			
		},
		data() {
			return {
				
				TripTypeGS:'',
				disYg: true,
				disYs: true,
			}
		},
		computed: {
			
			...mapGetters({
				getVipParamterByParNo:'common/getVipParamterByParNo',
			}),
			// 控制模式 默认不置灰 不隐藏  1置灰  2隐藏
			grayOrhidden(){
				return this.getVipParamterByParNo(20029,0 );
			},
			// 因公模式   1置灰  2隐藏  3 正常
			tripTypeGong(){
				let num = 3;
				if(this.grayOrhidden==2 && (this.vipParams==0 || this.vipParams==1)){
					num = 2
				}else if(this.grayOrhidden==1 && (this.vipParams==0 || this.vipParams==1)){
					num = 1
				}
				return num
			},
			// 因私控制  1置灰  2隐藏  3 正常
			tripTypeSi(){
				let num = 3;
				if(this.grayOrhidden==2 && (this.vipParams==0 || this.vipParams==2)){
					num = 2
				}else if(this.grayOrhidden==1 && (this.vipParams==0 || this.vipParams==2)){
					num = 1
				}
				return num
			}
		},
		watch: {
			tripType:{
				handler(val){
					//模块是否开启处理 0-不开启 1-因私 2-因公 3-都开启
					// 初始化因公因私
					this.TripTypeGS = this.initTripType(this.vipParams,this.tripType);

					if(this.TripTypeGS == 2){
						this.changeTripType(null,2)
					}
				},
				immediate: true,
			}
		},
		methods: {
			// 处理默认因公、因私逻辑处理：按照产品开启参数
			initTripType(vipParams,tripType){
				let num = 1;
				// 仅因私开启
				if(vipParams == 1){
					num =2;
				}else if(vipParams == 2){
					// 仅因公开启
					num =1;
				}else{ //都开启或者都不开启
					num = tripType
				}
				return num.toString()
			},
			
			// 切换因公因私
			changeTripType(el,tripType) {
				/**
				*  处理 radio 触发两次
				*  原因 input lable 标签在el-radio 中都被绑定事件
				*/ 
				if(el && el.target.tagName == 'INPUT'){
					return 
				}
				
				// 机票 ，火车票 ，酒店 ，模块是否开启处理 0-不开启 1-因私 2-因公 3-都开启
				if(this.vipParams!=0 && this.vipParams != 3 && this.TripTypeGS!=tripType){
					if(this.grayOrhidden == 1){
						this.$alert('根据差旅要求,不可切换因公因私模式', '提示', {
							confirmButtonText: '确定',
							type: 'warning',
						});    
						return false
					}      	
				}
				this.$emit('changeTripType', tripType);
			},
			// 动态填写样式
			calcStyle(){
				return {
					display:this.block
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.gs-text{
		width: 160px;
		// height: 40px;
		line-height: 30px;
	}
	
	// .gs-text ::v-deep {
	// 	.el-radio__inner{
	// 		width:20px;
	// 		height:20px;
	// 	}
	//     .el-radio__input.is-checked .el-radio__inner::after {
	//       content: "";
	//       width: 10px;
	//       height: 5px;
	//       border: 2px solid white;
	//       border-top: transparent;
	//       border-right: transparent;
	//       text-align: center;
	//       display: block;
	//       position: absolute;
	//       top: 4px;
	//       left: 3px;
	//       vertical-align: middle;
	//       transform: rotate(-45deg);
	//       border-radius: 0px;
	//       background: none;
	//     }
	//   }
</style>
