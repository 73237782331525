<template>
	<el-dialog
	    title="出差申请单"
	    :visible.sync="visible"
		:close-on-click-modal="false"
	    width="880px"
	 >
		<el-input v-model="inputType" placeholder="目的地、出行人、随行人、出差单号"  style="width:50%"><el-button slot="append" icon="el-icon-search" @click.native="searchCcsqd"></el-button></el-input> 
		<span class="ta-ft-right">
			申请时间
			<el-select v-model="monthValue" placeholder="请选择" class="ta-select" @change="changeValue(monthValue)">
			    <el-option
			      v-for="item in monthList"
			      :key="item.key"
			      :label="item.label"
			      :value="item.value"
				  >
			    </el-option>
			</el-select>
		</span>
	    <el-table :data="ccsqdList" 
			:header-cell-style="{ backgroundColor: 'rgba(64,160,255,0.1)'}" 
			:row-class-name="ccsqdTableRowClassName"
			v-loading = "loading"
			element-loading-background = "rgba(255, 255, 255, .9)"
			element-loading-text = "加载中，请稍后..."
			element-loading-spinner = "el-icon-loading"
		
			class="ta-table">
			<el-table-column  width="50">
				<template slot-scope="scope" >
					<el-radio v-model="chooseCcsqdId" :label="scope.row.ccsqdId" :disabled="scope.row.changeCount == 2" @click.native="chooseOneApplyForm($event,scope.row)"><span></span></el-radio>
				</template>
			</el-table-column>
			<el-table-column prop="ccsqdNo" label="申请单号" width="150">
				<template slot-scope="scope">
					<span class="ta-open-ccsqd" @click="openCcsqd(scope.row)">
						{{scope.row.ccsqdNo}}
					</span>
				</template>
			</el-table-column>
			<el-table-column prop="ccrAndSxr" show-overflow-tooltip label="出行人" width="150">
				
			</el-table-column>
			<el-table-column  label="目的地" width="100" show-overflow-tooltip>
				<template slot-scope="scope">
					<span v-if="scope.row.customizeFlag==2">
						{{scope.row.manyMdd}}
					</span>
					<span v-else>
						{{scope.row.mdd}}
					</span>
				</template>
			</el-table-column>
			<el-table-column prop="dateBegin" label="出差时间" width="170" >
				<template slot-scope="scope">{{scope.row.dateBegin}}至{{scope.row.dateEnd}}</template>
			</el-table-column>
			<el-table-column prop="ccsy" label="出差事由" width="130"></el-table-column>
			<el-table-column prop="zys" label="总预算" width="90" >
				<template slot-scope="scope">
					<span class="ystext">{{scope.row.zys | toFixed}}</span>	
				</template>
			</el-table-column>
	    </el-table>
		<el-pagination
			v-if="pageInfo.total"
			background
		    @current-change="handleCurrentChange"
		    :current-page.sync="pageInfo.pageNum"
		    :page-size="pageInfo.count"
		    layout="total, prev, pager, next"
		    :total="pageInfo.total">
		</el-pagination>
		<span slot="footer" class="dialog-footer">
		  <el-button @click="cancel(false)">取 消</el-button>
		  <el-button type="primary" @click="confirm">确 定</el-button>
		</span>
	</el-dialog>
		
</template>

<script>
	export  default {
		name:'ChooseCcsqdList',
		data(){
			return {
				inputType: '', //请输入目的地或出行人或随行人或出差单号  搜索符合条件出差申请单
				monthList:[
					{
						value: 'oneMonth',
					    label: '一个月内'
					}, {
					    value: 'twoMonth',
					    label: '两个月内'
					}, {
					    value: 'threeMonth',
					    label: '三个月内'	
					}		
				], // 处理3个月时间段内数据列表
				monthValue: 'threeMonth', // 默认选择1个月内出差申请单
				temporaryCcsqdInfo:{}, //临时选中的出差申请单信息
				
				dialogVisible: false, // 出差申请单弹框，默认关闭
				chooseCcsqdInfo: {}, //选中出差申请单后  赋值对象
				chooseCcsqdId: '', //选中出差申请单Id  
				ccsqdList: [],  // 出差申请单列表
				loading: false, // 加载状态
				
				
				pageInfo:{ //出差申请单分页信息
					total:0,
					count:10,
					pageNum:1,
				},
			}
		},
		props:{
			visible:Boolean,
			proType:[Number ,String],
			ccsqdNoOrId:String,
			
		},
		watch:{
			// 出差单号
			ccsqdNoOrId(val){
				if(val){
					this.getCcsqdDetail(val)
				}
			},
		},
		mounted() {
			// 查询列表
			this.queryTravelApply();
		},
		methods:{
			ccsqdTableRowClassName({row, rowIndex}) {
				if (row.changeCount == 2) {
				  return 'row-gray-disabled';
				} 
				return '';
			},
			// 打开弹框 ，员工选择已经审批通过的出差申请单进行预订
			queryTravelApply(){
				this.loading = true;
				let dateEnd = this.$dateTime.getToDay(); //今入日期	
				let dateStart = !this.dateStart ?this.$dateTime.getDateBegin(-3):this.dateStart;
				let queryString = {
					count:this.pageInfo.count,
					pageNum:this.pageInfo.pageNum,
					proType:this.proType,
					dateBegin: dateStart,
					dateEnd: dateEnd,
					ccr: this.inputType,
					dateType: 1, //1申请日期2出差日期
					status: 2, //0草稿,1审批中,2审批通过,3审批拒绝
					routeEffective:1,
				}
				this.$conn.getConn('travelRequest.vipCcsqdGetList')(queryString,(res)=>{
					let data = res.data || {}
					this.ccsqdList = data.list || [];
					this.pageInfo.total = data.total;
					this.loading = false;
				})
			},
			// 选中一条出差申请单信息
			chooseOneApplyForm(el,row) {
				if(el.target.tagName == 'INPUT'){
					return 
				}
				if(row.changeCount == 2){
					this.$alert('当前申请单，正处于变更中,请审批后，进行选择！')
					return false
				}
				this.chooseCcsqdId = row.ccsqdId;
				this.getCcsqdDetail(this.chooseCcsqdId)
				
			},

			getCcsqdDetail(ccsqdId,scene,routeId){
				if(!ccsqdId){
					return false
				}
				
				let obj = {
					ccsqdId:ccsqdId,
					proType:this.proType,
				}
				this.$conn.getConn('travelRequest.travelRequestInfo')(obj,(res)=>{
					let data = res.data || {};
					this.temporaryCcsqdInfo = data;	
				},(err)=>{
					
				})
			},
			// 选择日期改变当前月份查询出差申请单列表
			changeValue(value){
				if(value=='oneMonth'){
					this.dateStart = this.$dateTime.getDateBegin(-1); // 控制月份 整数向后， 负数向前	
				}else if(value=='twoMonth') {
					this.dateStart  = this.$dateTime.getDateBegin(-2);	
				}else if(value=='threeMonth'){
					this.dateStart  = this.$dateTime.getDateBegin(-3);	
				}
				this.pageInfo.pageNum = 1;
				this.queryTravelApply();
			},
			handleCurrentChange(e){
				this.pageInfo.pageNum = e;
				this.queryTravelApply();
			},
			// 输入查询条件搜索出差申请单
			searchCcsqd(){
				this.pageInfo.pageNum = 1;
				this.queryTravelApply()
			},
			// 取消
			cancel(bool){
				this.$emit('cancel',bool)
			},
			// 确认
			confirm(){
				if(JSON.stringify(this.temporaryCcsqdInfo)=='{}'){
					// this.dialogVisible = false;
					this.$confirm('请您选择出差申请单!', '提示', {
					        confirmButtonText: '确定',
					        cancelButtonText: '取消',
							showCancelButton: false,
							closeOnClickModal: false,
					        type: 'warning'
					    }).then(() => {
					     
					    }).catch(() => {
					                 
					    });  
					return false;
				}
				this.temporaryCcsqdInfo = {
					...this.temporaryCcsqdInfo,
					ccsqdId:this.temporaryCcsqdInfo.id,
				}
				this.$emit('confirm',this.temporaryCcsqdInfo)
			},
			
		},
		filters:{
			// 当前行程状态 行程状态 0 草稿；1审批中；2待预订；3已预订；4审批拒绝
			filterRouteStatus(routeStatus){
				let obj = {
					0:'草稿',1:'审批中',2:'待预订',3:'已预订',4:'审批拒绝'	
				}
				return obj[routeStatus]
			},
			toFixed(val){
				let num = 0;
				if(val){
					return val.toFixed(2)
				}else{
					return 0.00
				}
			}
		},
	}
	
	
</script>

<style scoped>
	
	
</style>