
// 主页面
let langMain = {
	// 导航
	homeTab:{
		home:'首页',
		book:'预订',
		myTravel:'我的差旅',
		myBill:'费控中心',
		myApp:'下载App',
	},
	login: {
	  introduction: '欢迎使用自动化装逼系统',
	  corpNo_holder:"企业代码",
	  name_holder: '用户名',
	  password_holder: '密码',
	  corpNo_tip: '请输入企业代码',
	  name_tip: '请输入用户名',
	  password_tip: '请输入密码',
	  enterAgain: '再次输入',
	  passwordT1: '请输入6-20位字符，需包含字符/数字',
	  remember: '记住密码',
	  forget: '忘记密码',
	  info: '你咋忘不了吃呢？',
	  button: '登 录',
	  phoneNumber_holder: "手机号码",
	  verifyCode: '验证码',
	  getYzmCode:'获取验证码',
	  resend: '重新发送',
	  phone_tip: '请输入手机号码',
	  verifyCode_tip:'请输入验证码',
	  personRegister:'个人注册',
	  corpRegister:'企业注册',
	  serviceAndPrivacy:"请先同意服务协议和隐私条款",
	  termsOfService: "请先同意服务条款",
	  serviceTelephone: "服务电话",
	  enterpriseContact:'企业接触',
	  servicePersonTimes: "服务人次",
	  saveTravelServiceCB:"为服务企业节省差旅成本",
	},
	register:{
		username: '姓名',
		phoneNumber: "手机号码",
		verifyCode: '验证码',
		submitRegister: '提交注册',
		login: '登录',
		haveAnAccount: '已有账号，去',
		phoneNumber_tip:'请输入手机号码',
		verifyCode_tip:'请输入验证码',
		username_tip:'请输入姓名',
		invalidPhone:'手机号码无效',
		registerSuccess:'注册成功',
		enterpriseFullname:'请输入企业全称',
		enterpriseShortname: '请输入企业简称',
		enterpriseAddress: '请输入企业地址',
		industry:'请选择企业行业',
		corpScale:'请选择企业规模',
		provinceName:'请选择省份名称',
		cityName:'请选择城市名称',
		linkPhone:'请输入联系电话',
		linkman:'请输入联系人',
		postCode:'请输入邮编',
		smsCode:'请输入验证码',
		email:'请输入邮箱',
		correctEmail:"请输入正确的电子邮箱格式",
		passwordLeng:"密码长度 6 至 20位",
		passwordType:"密码由字母加数字或符号至少两种以上字符组成的6-20位半角字符，区分大小写，不能含空格",
		passwordAtypism:"两次输入密码不一致",
	}
	
	
	
}


export default langMain