const actions = {
	// 退出清除火车票缓存信息
	setInitTrInfo(context, payload){ // 当传入参数时，需要payload
		// 此处举例用Promise包装了异步操作
		context.commit('initTrInfo') // mutation中的方法用commit回调
		// return new Promise((resolve,reject) => {
		// 	setTimeOut(() => {
		// 		context.commit('clearInfo') // mutation中的方法用commit回调
		
		// 		resolve('11111')
		// 	},1000)
		// })	
	}
}
export default actions;