<template>
	<div class="yj-nav">
		<div class="yj-nav-content">
			<!-- 欢迎登录空港嘉华企业差旅系统 -->
			<span>
				欢迎登录{{configData.corpName}}差旅系统 
				
				<span style="margin-left: 10px;">当前企业:[<el-button type="text" style="cursor:initial">{{vipCorp.fullname}}</el-button>]</span>
			</span>
			<div>
				<!-- <el-button class="btn-old" @click="goOldEdition">返回旧版</el-button> -->
				<el-button class="enter-etsms-admin" v-if="empInfo.isAdmin==1" type="text" @click="enterEtsmsAdmin()">进入管理端</el-button>
			</div>
			
			
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				
			}
		},
		computed:{
			userInfo(){
				let userInfo = this.$common.sessionget('user_info') || {};
				return userInfo
			},
			empInfo(){
				return this.userInfo.empInfo || {} ;
			},
			vipCorp(){
				return this.userInfo.vipCorp || {};
			},
			configData(){
				return this.$common.getConfigData();
			}
			
		},
		methods:{
			enterEtsmsAdmin(){
				var origin = window.location.origin;
				var path = '/admin/#/app/companyinfo';
				let routerUrl = origin + path;
				// 远程跳转
				window.open(routerUrl,"_blank");
				// let path = '/admin/#/app/companyinfo'
				// let routerUrl = this.$router.resolve({path:path})
				// // 远程跳转
				// window.open(routerUrl.href,"_blank");
					
			},
			// 跳转obt 老版本
			goOldEdition(){
				// 新版obt 2022，旧版 2017
				this.$common.localset("obt-version", "2017"); 
				
				// window.location.href="/obt-web/blocks/jump.html"
				window.location.replace("/obt-web/blocks/jump.html")
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.yj-nav{
		width: 100%;
		height:26px;
		background: #DDDFE3;
		font-size: 12px;
		.yj-nav-content{
			height: 100%;
			width: 1200px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0 auto;
			.enter-etsms-admin{
				
			}
			.btn-old{
				padding: 4px 10px;
				border: 1px solid #E5EAF3;
				border-radius: 10px;
				color: #637697;
				font-size: 12px;
			}
		}
	}
	
</style>
