//  用于common
const state = {
	//企业参数模块信息
	vipParamterList: {},
	corpMdParams: {},
	ccProjectData: {},
	costCenterData: {},


	// 出差申请单信息
	ccsqdInfo: {},

	headImg: '', //头像图片
	// historAvatarList:[], // 可存储3张历史图片

	useNameclbz: '', //本次使用某个出行人的差标
}
export default state
