import Vue from "vue";
import VueRouter from "vue-router";

// 主路由
import routeMain from "./routeMain.js"

import common from "@/plugins/common.js"

Vue.use(VueRouter);

// 解决路由重定向的报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}





let routes = new Set([...routeMain]);

const router = new VueRouter({
	routes,
});
//使用router.beforeEach对路由进行遍历，设置title
router.beforeEach((to, from, next) => {
    //beforeEach是router的钩子函数，在进入路由前执行
    if (to.meta.title) {
        //判断是否有标题
        document.title = to.meta.title
    } else {
		var config = common.getPageSettingInfo();
        document.title = config.etBrowserName || '';
		if( config.etBrowserLogo){
			document.querySelector('link[rel*="icon"]').href = config.etBrowserLogo || '';
		}
		
    }
    next()
})
export default router;
