/* vuex 缓存数据文件 */
// 初始化
import store from '@/store';
var init = {};
/*
*   清除vuex 缓存 需求全部清除 部分清除
* 	不传 全部清除
* 	传 name 为各个模块store的 文件名/初始化方法名
* 	
*/
init.vuexCache = function(name){
	// 配置初始化集合
	var vuexObj={
		'tk':'tk/setInitTkInfo',
		'train':'train/setInitTrInfo',
		'htCn':'htCn/setInitHtCnInfo',
		'common':'common/setInitialCenterInfo',
	}
	if(name){
	    // 模块名/方法名
		if(name.indexOf('/')>-1){
			store.dispatch(name);
		// 仅模块名
		}else if(vuexObj[name]){
			store.dispatch(vuexObj[name]);
		}
		
	
		
	}else{
		for(var key in vuexObj){
			if(vuexObj[key]){
				store.dispatch(vuexObj[key]);
			}
			
		}
		// 不要脸的写法
		// store.dispatch("tk/setInitTkInfo");
		// store.dispatch("train/setInitTrInfo");
		// store.dispatch("htCn/setInitHtCnInfo");
		// store.dispatch("common/setInitialCenterInfo");
	}
}
export default init