<template>
	<div class="ap-main" :style="{height: (vh>1?vh*80:120)+'px'}">
		<div class="steps">
			<el-steps direction="vertical" :active="status" class="sp-item">
				<el-step title="提交审批" status="success" v-if="JSON.stringify(approvalInfo)!='{}'">
					<div slot="description">
						<span>{{approvalInfo.sentTime}}</span>
						<span v-html="approvalInfo.sentEmpName"></span>
					</div>
				</el-step>
				<el-step status="wait" v-if="JSON.stringify(approvalInfo)=='{}'" style="line-height: 80px;">
					<div slot="description" style="line-height: 80px;font-size: 14px;">
						未提交审批
					</div>
				</el-step>
				<el-step v-for="(item, index) in approvalInfo.spjdInfoList" :key="index"
					:status="item.flowState==1?'success':item.flowState==2?'error':item.flowState==0?'wait':'finish'">
					<div slot="description">
						<div class="font16">
							<span v-if="item.flowState==0">未开始</span>
							<span v-if="item.flowState==1">已通过</span>
							<span v-if="item.flowState==2">已拒绝</span>
							<span v-if="item.flowState==3">审批中</span>
						</div>
						<div v-if="item.flowState==1 || item.flowState==2" style="display: flex;">
							<span
								v-html="item.completeTime+item.approveImg+'审批人：'+item.approveEmpName+'审批意见：'+item.appRemark"></span>
						</div>
						<div v-else>
							<div v-html="' 审批人：'+item.approveEmpName"></div>
						</div>
					</div>
				</el-step>
			</el-steps>
			
			<div class="setqd">
				<div v-if="spStatus==0" class="bordersp1 iconfont yj-icon-daitijiao_icon"></div>
				<div v-if="spStatus==1" class="bordersp2 iconfont yj-icon-shenpitongtuo_icon"></div>
				<div v-if="spStatus==2" class="bordersp3 iconfont yj-icon-shenpijujue_icon"></div>
				<div v-if="spStatus==3" class="bordersp4 iconfont yj-icon-shenpizhong_icon"></div>
				<!-- <div class="set-item"
					:class="['setqd',{bordersp1:spStatus==0,bordersp2:spStatus==1,bordersp3:spStatus==2,bordersp4:spStatus==3,}]">
					{{spStatus==1?'审批通过':spStatus==2?'审批拒绝':spStatus==3?'审批中':'未开始'}}
				</div> -->
			</div>
		</div>

		<!-- <div class="ddcon" :style="{padding:(this.vh*20+'px')+' '+(10+'px')}" v-if="showPrice">
			<div class="ddtext">
				{{text}}
			</div>
			<div class="ddprice">
				¥ {{price}}
			</div>
		</div> -->
	</div>
</template>

<script>
	export default {
		name: "ApprovalProgress",
		data() {
			return {
				vh: 1, // 高度
				status: 1, // 审批状态
				approvalInfo: {}, // 审批人信息
				spStatus: 0,
				spjd:[],  // 当前人的审批进度 状态
			}
		},
		props: {
			// showPrice: { // 是否显示订单金额
			// 	type: Boolean,
			// 	default: true,
			// },
			// text: { // 显示的订单文本信息
			// 	type: String,
			// 	default: "订单金额",
			// },
			// price: { //订单金额
			// 	type: [Number, String],
			// 	default: 0,
			// },
			orderId: { //订单id
				type: [String,Number],
				default: "",
			},
			orderType: { //订单类型
				type: [String,Number],
				default: "",
            },
			resetspjd:{
				type:Boolean,
				default: false,
			}
		},
		computed: {
			userInfo() {
				let userInfo = this.$common.sessionget('user_info');
				return userInfo;
			},
			empInfo() {
				return this.userInfo.empInfo || {};
			}
		},
		
		mounted() {
			this.getVipOrderAppProgress()
		},
		watch:{
			resetspjd(bool){
				if(bool){
					this.getVipOrderAppProgress();
				}
			}
		},
		methods: {
			// 获取审批规则
			getVipOrderAppProgress() {
				let obj = {};
				obj = {
					orderId: this.orderId,
					orderType: this.orderType,
				}
				this.$conn.getConn('commu.appProgress', {
					jsfile: false
				})(obj, (res) => {
					let data = res.data || {};
					if (JSON.stringify(data) == '{}') {
						return
					}else{
						this.approvalInfo = data;
						this.spjd = this.approvalInfo.spjd.split('/')
						if(this.approvalInfo.spjdInfoList.length>0){
							this.approvalInfo.spjdInfoList.map((item, index) => {
								if (item.approveEmpName && !item.approveImg && item.flowState != 3) {
									let approveEmpName = [];
									approveEmpName = item.approveEmpName.split(">");
									item.approveEmpName = approveEmpName[1];
									item.approveImg = approveEmpName[0] + '>';
								}
								// 表示当前审批人不是最后审批人 一定是多人审批
								if(this.spjd[0] < this.spjd[1] &&  item.flowState == 3){
									this.spStatus = item.flowState;
									this.status = index + 2;
								}else if(this.spjd[0] == this.spjd[1]){
									// 当前审批人是最后审批人，审批中
									this.spStatus = item.flowState;
									this.status = index + 2;
								}

								return item
							})
							this.vh = this.approvalInfo.spjdInfoList.length + 1;
						}
						
						this.$emit("showProgress", data,this.spjd[0],this.spStatus);
					}
		
				}, (err) => {
					this.$message({
						message: err.tips,
						type: 'info'
					})
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.ap-main {
		display: flex;
		justify-content: space-between;
		.font16 {
			font-size: 16px;
		}

		.steps {
			display: flex;
			justify-content: space-between;
			width: 70%;
			.sp-item {
				width: 100%;
			}

			.setqd {
				width: 80px;
				height: 80px;
				transform: rotate(-30deg);
			}

			.bordersp1 {
				font-size: 80px;
				color: #424242;
				
			}

			.bordersp2 {
				font-size: 80px;
				color: #67C23A;
			}

			.bordersp3 {
				font-size: 80px;
				color: #FF0000;
			}

			.bordersp4 {
				font-size: 80px;
				color: #4E90F1;
			}
		}

		.ddcon {
			border-left: 1px solid #dbdbdb;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			text-align: center ;
			width: 25%;

			.ddtext {
				width: 100%;
			}

			.ddprice {
				width: 100%;
				font-size: 18px;
				line-height: 28px;
				color: #FF9537;
			}
		}

		/deep/ img.userhead {
			width: 20px;
			height: 20px;
			margin-left: 10px;
			vertical-align: top;
		}
	}
</style>
