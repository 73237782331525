export const htCnState = () => {
  return {
    night: "1", //入住几晚
    price: "", //价格
    homeRooms: "1", //房间数量
    roomInfo: {}, // 房间的信息
    hotelInfo: {}, //酒店的信息
    servicePrice: "", //服务费价格
    cusRoomsState: [], //调整入住房间的数据
    ninghtRomms: [], // 入住房间数据
    description: "", // 取消规则
    isFreeMeal: 0,
    htCnSonTags: {
      start: [],
      input: [],
      hotel: [],
      services: [],
    }, //数据
    htCnParams: {
      ccsqdCityName: "", // 城市名字
      //搜索参数
      brandIds: "", //品牌id，多个以逗号隔开 ,
      cityId: "", //城市或者商圈id
      checkInDate: "", //日期开始时间
      checkOutDate: "", // 日期结束时间
      businessZone: "", //商圈id，searchType=2时，必传 ,
      propertyIds: "", //服务设施
      district: "", // 行政区ID
      keywords: "", //关键字
      lat: "",
      lon: "",
      sort: "", //排序
      jdType: 1, //是否是协议
      pageNum: 1, //当前页数，不能小于1 ,
      propertyIds: "", //服务设施id，多个以逗号隔开 ,
      priceRange: "", //价格范围：不传代表不限；范围以-隔开；如果是150元以下，传0-150；如果是800元以上，传800-999999 ,
      searchType: "", //查询类型，1 按行政区；2 按商圈；3 按经纬度；4 指定酒店附近；默认 0 ,
      starRate: "", //挂牌星级：不限就不传；二星级以下,传2；三星传3A；以此类推,多个以英文逗号隔开 ,
      count: 10,
    },
    htCnQuery: {
      //这里用来保存因公模式下的
      tripType: 1, // 1-因公 2-因私 null-为了解决triptype控件传值为数字类型的问题
      gkfsRole: 1, // 10999    出差申请单模式下   是否按出差申请单进行管控   默认  0不管控 1： 管控  2安差标管控
      changeRouteRole: 0, // 10991    查询出差申请单可更改行程 // 0不可更改  1可以更改   2的时候为行程中城市任意更改 3的时候出发达到城市限制可选
      changeDateRole: 0, // 10992    查询出差申请单可变更日期
      changeDateRange: 0, // 10993    查询出差申请单日期范围  默认 0天     前后几天
      params10995: 0, //  "成本中心是否必填" 1显示，0不显示"
      params10996: 0, //   "项目是否必填"   1显示，0不显示"
      params20002: 0, //   "订单填写页面是否显示成本中心，1显示，0不显示"
      params20003: 0, //   "订单填写页面是否显示项目，1显示，0不显示"

      // 存储下员工最低职级
      empRank: 1, //   职级
      useNameclbz: "", // 本次预订使用谁的差旅标准
      minEmpRankEmpId: "", // 当前员工差旅标准的职级

      // 违背原因
      violateCode: "", //违背事项代码
      violateItem: "", //违背事项说明
      reasonCode: "", //违背原因代码
      reasonDesc: "", //违背原因说明
    },
    htCnCcsqD: {
      // 这里用来保存选中的出差申请单信息
    },
    lsrList: [], //  因私模式下默认为当前登录人，
    cxrList: [],

    htCnPolicy: {}, //保存酒店的差旅政策
  };
};

const state = htCnState();
export default state;
