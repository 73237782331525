<template>
	<el-card class="r-main" :style="{'margin-top':marginTop,}">
		<h1 class="textLeft r-title">
			<span v-if="orderType==11002 || orderType==11005 || orderType==11010"><span style="color:red;">* </span>退票原因</span>
			<span v-if="orderType==11003 || orderType==11006 || orderType==11011"><span style="color:red;">* </span>改签原因</span>
			<span class="r-title-span">(企业要求必选)</span>
		</h1>
		
		<div class="textLeft marTop15" v-if="showTGtext">
			<el-checkbox v-model="checkText" @change="chooseChange">{{text}}</el-checkbox>	
		</div>
		<div class="marTop26 textLeft setSelect">
			<el-select v-model="reasonId" placeholder="请选择原因" class="w675" @change="getReason(reasonId,options)">
			   <el-option
			      v-for="item in options"
			      :key="item.id"
			      :label="item.cName"
			      :value="item.id">
			    </el-option>
			</el-select>
		</div>
		<!-- 其他原因 -->
		<div v-if="checkQt" class="marTop15 textLeft">
			
			<el-input  type="textarea" v-model="reasonName" maxlength="200" placeholder="请输入其他原因,字数限制200" @change="getChange('reasonName')"></el-input>
			<span v-if="(200 - reasonName.length)==0">输入文字已达到上限</span>
			<span v-if="(200 - reasonName.length)>0 && (200 - reasonName.length)<200">您还可以输入{{200 - reasonName.length}}个文字</span>
		</div>
		<!-- 自愿改签原因 -->
		<div v-if="isShowChangeReasonVip" class="marTop15 textLeft">
			<p style="margin-bottom: 10px;">
				<span style="color:red;font-size: 18px;" >*</span>原因备注
			</p>
			<el-input  type="textarea" v-model="changeReasonVip" maxlength="200" placeholder="请输入其他原因,字数限制200" @change="getChange('changeReasonVip')"></el-input>
			<span v-if="(200 - changeReasonVip.length)==0">输入文字已达到上限</span>
			<span v-if="(200 - changeReasonVip.length)>0 && (200 - changeReasonVip.length)<200">您还可以输入{{200 - changeReasonVip.length}}个文字</span>
		</div>
		<div v-if="checkText" class="textLeft">
			<yj-up-load @successfile="getimageURL"></yj-up-load>
			<span v-if="orderType==11002 || orderType==11005 || orderType==11010">您选择的是非自愿退票原因，请上传退票原因凭证</span>
			<span v-if="orderType==11003 || orderType==11006 || orderType==11011">您选择的是非自愿改签原因，请上传改签原因凭证</span>
		</div>
		
	</el-card>	
</template>

<script>
	import { mapGetters } from 'vuex';
	export default{
		name:'Reason',
		props:{
			marginTop: {
				type: String,
				default: '',
			},
			orderType:{ // 订单类型
				type:  [String,Number],
				default: 11001,
			},
			params:{
				type: [String,Number],
				default: 143,
			},
			showTGtext:{ // 是否显示退改签文本
				type: Boolean,
				default: false,
			},
			text:{ // 文本信息
				type: String,
				default: '非自愿退票 ！由于航班取消、提前、延误、航程改变或其他突发状况，造成您不能乘机，您可以申请退票。',
			}
		},
		data() {
			
			return {
				options:[], 
				reasonName:'',  // 退改签理由
				reasonId:"", // 原因id
				checkText: false, // 是否选中非自愿退票
				imageUrl: '', // 图片路径
				isVoluntarily: 1, // 是否自愿 1是 2否
				checkQt: false, // 是否选中其他原因

				changeReasonVip:'', // 企业改签原因
			}
		},
		computed:{
			// 总公司信息
			companyData(){ 
				let info = this.$common.getConfigData();
				return info || {};
			},
			// 改签原因自愿改签时，是否必填改签原因    0 不需要必填  1需要必填
			gaiQianReasonGk(){
				return this.getVipParamterByParNo('20055',0)
			},
			...mapGetters({
				getVipParamterByParNo:'common/getVipParamterByParNo',
			}),
			// 是否显示企业改签原因
			isShowChangeReasonVip(){
				let bool = false;
				if(this.gaiQianReasonGk == 1 && (this.reasonId == '13401' ||this.reasonId == '14501')){
					bool = true;
				}

				return bool
			}
		},
		mounted() {
			this.queryBClass(this.params)
		},
		methods: {
			// 查询原因
			queryBClass(parNo){
				this.options = [];
				let params = {parNo: parNo};
				this.$conn.getConn('commu.getBClass')(params,(res)=>{
					let data = res.data || {};
					this.options = data.list || [];
					if(this.options.length == 1){
						this.getReason(this.options[0].id,this.options)
					}
					// 火车票退票增加其他原因
					if(parNo==143 || parNo==145){
						var obj = {
							cName: "其他原因",
							id: "QT",
							parNo: "143",
							sn: 0,
							typeNo: "TREFUNDREASON",
						}
						this.options.push(obj)
					}
				},(err)=>{
					this.$message({
						message:err.tips,
						type:'error'
					})
				})
			},
			// 获取线上图片URL
			getimageURL(imgurl){
				this.imageUrl = imgurl;
				this.emitReasonObj();
			},
			// 回返改签 退票  原因
			getReason(reasonId,options){
				// 13401  机票自愿改签    14501  火车票自愿改签
				if(reasonId=='QT' || reasonId=='13006'  ){
					this.checkQt = true;
				}else{
					this.checkQt = false;
					this.reasonName = "";
				}
				
				let chooseReasonArr = options.filter((item,index)=>{
					return item.id == reasonId
				}) || [];
				let obj = chooseReasonArr[0] || {};
				this['reasonName'] = obj['cName'];
				this['reasonId'] = obj['id'];
				// this['isVoluntarily'] = this.isVoluntarily;
				// this['imageUrl'] = this.imageUrl;
				this.emitReasonObj();
			},
			// 发送原因对象
			emitReasonObj(){
		
				var obj = {
					reasonName:this.reasonName,
					reasonId:this.reasonId,
					isVoluntarily:this.isVoluntarily,
					imageUrl:this.imageUrl,
					gaiQianReasonGk:this.gaiQianReasonGk, // 企业改签，自愿改签，是否支持手动填写企业原因
					changeReasonVip:this.changeReasonVip, // 企业变更原因 
				}
				this.$emit('refundChangeReason', obj)	
			},
			// 输入退改签原因后，返回数据
			getChange(){
				this.emitReasonObj()
			},
			// 当选中 非自愿退票时，退票原因改变
			chooseChange(){
				this.reasonName = '';
				this.reasonId = '';
				this.checkQt = false;
				if(this.checkText){
					// 获取不同业务类型的proNo参数
					this.isVoluntarily = 2;
					let number = '';
					if(this.orderType==11002 || this.orderType==11005 || this.orderType==11010){
						number = this.$dict.parNoRefundConfig(this.orderType);
					}else{
						number = this.$dict.parNoChangeConfig(this.orderType);
					}
					
					this.queryBClass(number);
				}else{
					this.isVoluntarily = 1;
					this.queryBClass(this.params);
				}
				this.emitReasonObj();
			},	
		}
	}
</script>

<style scoped lang="scss">
	.el-card.is-always-shadow{
		box-shadow: 1px 2px 2px 0px rgba(13, 2, 0, 0.02);
		border-radius: 5px ;
	}
	.r-main{
		color: #424242;
		font-family: '微软雅黑';
		.r-title{
			font-size: 24px;
			font-weight: 600;
			line-height: 24px;
			.r-title-span{
				color: #808080;
				font-size: 15px;
				padding-left: 10px;
				letter-spacing: 1px;
			}
		}
		.setSelect{
			/deep/ .el-input__inner{
				width: 675px;
				height: 45px;
				background: #F4F9FF;
				border: 3px solid #006BB9;
				border-radius: 1px;
			}
		}
		.marTop30{
			margin-top: 30px;
		}
		.textLeft{
			text-align: left;
		}
		.marTop26{
			margin-top: 26px;
		}
		.marTop15{
			margin-top: 15px;
		}
		.inlineB{
			display: inline-block;
		}
		.font15{
			font-size: 15px;
		}
		.normal{
			font-weight: normal;
		}
		.colorH{
			color: #ccc;
		}
		.w675{
			width: 675px;
		}
		/deep/ .el-input__inner{
			height: 45px;
			line-height: 40px;
		}
		/deep/ .el-card__body{
			padding: 30px;
		}
	}
</style>
