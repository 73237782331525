/*
 *   本文件为主路由文件
 *    公共路由文件
 *
 *
 */
// 机票路由
import { routeTk, routeTkDetail, routeTkEdit } from "./routeComponent/routeTk.js"
// 国内酒店
import routeHtCn from "./routeComponent/routeHtCn.js"
// 火车票业务
import { routeTr, routeTrEdit } from "./routeComponent/routeTr.js"
// 个人 中心
import userCenter from "./routeComponent/userCenter.js"
// 国际机票需求单
import routeInTk from "./routeComponent/routeInTk.js"
// 国际酒店需求单
import routeInHt from "./routeComponent/routeInHt.js"
//希安康出差申请单路由

import routeXakCcsqd from './routeComponent/xakCcsqd.js'

import common from "./../plugins/common.js"
// 打印模板
import routeTpl from "./routeComponent/routeTpl.js"

import routeOaTmc from "./routeComponent/routeOaTmc.js"
const routeMain = [{
		path: '/',
		redirect: '/login'
	},
	{
		path: '/login',
		name: 'Login',
		component: (res) =>{
			var apiconfig = common.getConfigData() || {};
			
			if(apiconfig.homePageType == 'xak'){
				return	 import( /* webpackChunkName: "routeMain" */ "./../views/login/XakLogin");
			}else if(apiconfig.homePageType == 'pxhk'){
				return	 import( /* webpackChunkName: "routeMain" */ "./../views/login/Login");
			}else{
				return	 import( /* webpackChunkName: "routeMain" */ "./../views/login/Login");
			}	
		} 
	},
	{ // 个人注册
		path: '/personRegister',
		name: 'PersonRegister',
		component: () => import( /* webpackChunkName: "routeMain" */ "./../views/login/register/PersonRegister"),
		
	},
	{ //企业注册
		path: '/corpRegister',
		name: 'CorpRegister',
		component: () => import( /* webpackChunkName: "routeMain" */ "./../views/login/register/CorpRegister"),
	},
	{ // 忘记密码
		path: '/forgetPassword',
		name: 'ForgetPassword',
		component: () => import( /* webpackChunkName: "routeMain" */ "./../views/login/ForgetPassword"), 	
	},
	...routeTpl, // 模板路由
	...routeOaTmc, // tmc 路由
	{
		path: '/yjTravel',
		component: () => import( /* webpackChunkName: "routeMain" */ "./../YjTravel"),
		redirect: 'yjTravel/home',
		children: [{
				path: "home",
				name: 'Home',
				component: () => import( /* webpackChunkName: "routeMain" */ "./../views/home/homePage/Home"),
			},
			{
				path: "download",
				name: 'Download',
				component: () => import( /* webpackChunkName: "routeMain" */ "./../views/home/Download"),
			}, {
				path: "myTravel",
				name: 'MyTravel',
				component: () => import( /* webpackChunkName: "routeMain" */ "./../views/home/MyTravel"),
				redirect: 'myTravel/userCenter',
				children: [
					// 个人中心
					...userCenter,
				]
			},
			{
				path: "book",
				name: 'Book',
				component: () => import( /* webpackChunkName: "routeMain" */ "./../views/home/Book"),
				redirect: 'book/tkRouter',
				children: [
					...routeTk, // 机票业务					
					...routeHtCn, // 国内酒店业务				
					...routeTr, // 火车票业务
					...routeInTk, //国际机票需求单
					...routeInHt, //国际酒店需求单
					
				]
			},
			{
				path: "myBill",
				name: 'MyBill',
				component: () => import( /* webpackChunkName: "routeMain" */ "./../views/home/MyBill"),
			},
		],
	},
	// 用于无业务导航路由
	{
		path:"/yjEdit",
		component:()=> import( /* webpackChunkName: "routeYjEdit" */ "./../YjEdit"),
		children:[
			...routeTkEdit, //机票业务编译
			...routeTrEdit, //火车票业务
		],
	},
	{
		path:"/yjDetail",
		component:()=> import( /* webpackChunkName: "routeYjDetail" */ "./../YjDetail"),
		children:[
			...routeTkDetail, // 机票业务
				// 支付
				{
					path: 'payment',
					name:'Payment',
					component:  () => import(/* webpackChunkName: "payment" */ "./../views/pay/Payment"),
					meta:{
						title:"支付"
					}
				},
				// 支付成功
				{
					path: 'paySuccess',
					name:'PaySuccess',
					component:  () => import(/* webpackChunkName: "payment" */ "./../views/pay/PaySuccess"),
					meta:{
						title:"支付成功"
					}
				},
				{
					//火车票详情页
					path: 'trainDetail',
					name:'TrainDetail',
					component:  () => import(/* webpackChunkName: "routeYjDetail" */ "./../views/tr/TrainDetail"),
					meta:{
						title:"火车票详情"
					}
				},
				{	//火车票退票详情页
					path: 'trainRefundDetail',
					name:'TrainRefundDetail',
					component:  () => import(/* webpackChunkName: "routeYjDetail" */ "./../views/tr/TrainRefundDetail"),
					meta:{
						title:"火车票退票详情"
					}
				},
				{   //火车票改签详情页
					path: 'trainChangeDetail',
					name:'TrainChangeDetail',
					component:  () => import(/* webpackChunkName: "routeYjDetail" */ "./../views/tr/TrainChangeDetail"),
					meta:{
						title:"火车票改签详情"
					}
				},
			
                {	//酒店正常单订单详情页
					path: 'htCnOrderDetail',
					name:'HtCnOrderDetail',
					component:  () => import( /* webpackChunkName: "routeMain" */ "@/views/htCn/HtCnOrderDetail"),
					meta:{
						title:"酒店正常单订单详情"
					}
				},
                {	//酒店退单详情页面
					path: 'htCnRefundDetail',
					name:'HtCnRefundDetail',
					component:  () => import( /* webpackChunkName: "routeMain" */ "@/views/htCn/HtCnRefundDetail"),
					meta:{
						title:"酒店退单详情"
					}
				},
                {	//酒店退单进度页面
					path: 'htCnRefundSpeed',
					name:'HtCnRefundSpeed',
					component:  () => import( /* webpackChunkName: "routeMain" */ "@/views/htCn/HtCnRefundSpeed"),
				},
                {	//酒店退房下单
					path: 'htRefundRooms',
					name:'HtRefundRooms',
					component:  () => import( /* webpackChunkName: "routeMain" */ "@/views/htCn/HtRefundRooms"),
					meta:{
						title:"酒店退房下单"
					}
				},
				{	//国际机票需求单详情
					path: 'inTkDetail',
					name:'InTkDetail',
					component:  () => import( /* webpackChunkName: "routeMain" */ "@/views/international/interTk/InTkDetail"),
					meta:{
						title:"国际机票需求单详情"
					}
				},
				{	//国际酒店需求单详情
					path: 'inHtDetail',
					name:'InHtDetail',
					component:  () => import( /* webpackChunkName: "routeMain" */ "@/views/international/interHt/InHtDetail"),
					meta:{
						title:"国际酒店需求单详情"
					}
				},
				{	//用车单订详情
					path: 'carDetail',
					name:'CarDetail',
					component:  () => import( /* webpackChunkName: "routeMain" */ "@/views/car/CarDetail"),
					meta:{
						title:"用车单订详情"
					}
				},
				{	//泊车单订详情
					path: 'parkDetail',
					name:'ParkDetail',
					component:  () => import( /* webpackChunkName: "routeMain" */ "@/views/park/ParkDetail"),
					meta:{
						title:"泊车单订详情"
					}
				},
                {	//会议详情
                    path: 'miceDetail',
                    name:'miceDetail',
                    component:  () => import( /* webpackChunkName: "routeMain" */ "@/views/mice/miceDetail"),
                    meta:{
                        title:"会议会展详情"
                    }
                },

				]
	},
	{
		path: "/completed",
		name: "Completed",
		component: () =>
			import(/* webpackChunkName: "routeMain" */ "./../views/placeOrder/Completed.vue"),
		meta:{
			title:"完成订单"
		}	
	},
	{ // 新增出差申请单
		path: "/newCcsqd",
		name: "NewCcsqd",
		component: () =>
		import(/* webpackChunkName: "routeMain" */ "./../views/newccsqd/NewCcsqd.vue"),
		meta:{
			title:"出差申请单"
		}
	},
	{// 出差申请单详情
		path: "/ccsqdDetail",
		name: "CcsqdDetail",
		component: () =>
		import(/* webpackChunkName: "routeMain" */ "./../views/newccsqd/CcsqdDetail.vue"),
		meta:{
			title:"出差申请单详情"
		}
	},
	{// 行程单
		path: "/tkRouteItinerary",
		name: "TkRouteItinerary",
		component: () =>import(/* webpackChunkName: "routeMain" */ "./../views/tk/TkRouteItinerary.vue"),
		meta:{
			title:"机票行程单"
		}
	},
    {
        path: '/xakCcsqd',
        name:'XakCcsqd',
        component: () =>import(/* webpackChunkName: "routeMain" */ "@/views/xakCcsqd/xakCcsqd"),
        children:[...routeXakCcsqd]
    },
    {   
        path: '/xakCcsqdDetail',
        name:'XakCcsqdDetail',
        component:  () => import( "@/views/xakCcsqd/CcsqdDetail"),
    },
    {   
        path: '/xakCcsqdChange',
        name:'XakCcsqdChange',
        component:  () => import( "@/views/xakCcsqd/xakCcsqdChange"),
    },
    {   
        path: '/changeCcsqdDetails',
        name:'ChangeCcsqdDetails',
        component:  () => import( "@/views/xakCcsqd/changeCcsqdDetails"),
    },
    {   
        path: '/ccsqdPreview',
        name:'CcsqdPreview',
        component:  () => import( "@/views/xakCcsqd/ccsqdPreview"),
    },
	{ // 任务管理器
		path: "/taskList",
		name: "TaskList",
		component: () =>
		import(/* webpackChunkName: "routeMain" */ "./../views/task/TaskList.vue"),
		meta:{
			title:"任务管理器"
		}
	},
	{ // 客户在线对账
		path: "/cusOnlineReconilite",
		name: "CusOnlineReconilite",
		component: () =>
		import(/* webpackChunkName: "routeMain" */ "./../views/onlineReconilite/CusOnlineReconilite.vue"),
		meta:{
			title:"在线对账"
		}
	},

	{ //  vue2 * vue3 pathMatch(.*)
		path: "*", 
		name: "NotFound",
		component: () =>
		import(/* webpackChunkName: "routeMain" */ "./../views/NotFound.vue"),
		meta:{
			title:"404"
		}
	},
    

]



export default routeMain