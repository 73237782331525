<template>
	<div class="vlt-main">
		<el-dialog
			title="请确定本次差旅的违背原因"
			:visible.sync="visible"
			width="40%"
			:append-to-body="true"
			class="vlt-tit"
			:before-close="handleClose"
		  >
			<div >
				<p style="line-height: 28px;">根据贵公司规定，您本次出行违背了以下差旅标准:</p>
				<p style="color:red;line-height: 28px;">{{reason}}</p>
				<p style="line-height: 28px;" >请您选择违背原因：<span :class="{'color-red':bx,}" v-if="bx">此项必选</span></p>
				<div  style="margin: 20px 0;max-height: 200px;overflow-y: auto;border: 1px solid #ccc;padding: 20px;">
					<div>
						<el-radio-group v-model="reasonId" v-for="item in reasonList" :key="item.index" style="width: 100%;" >
						    <el-radio :label="item.reasonCode"  style="line-height: 30px;" @click.native="chooseReasonId($event,item)" v-show="item.reasonCode!='QT'">{{item.reasonDesc}}</el-radio>	
                            <div style="display: flex;justify-content: flex-start;" v-show="item.reasonCode=='QT'">
                                <el-radio :label="item.reasonCode" style="line-height: 30px;" @click.native="chooseReasonId($event,qtValueObj)">其他</el-radio>
                                <el-input v-model="qtValueObj.reasonDesc" style="width: 40%;" :disabled="disabled"></el-input>
                            </div>
						</el-radio-group>

						
					</div>
				</div>
			</div>
			 
		    <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="chooseReason">确 定</el-button>
		    </span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'Violation',
		props: {
			// 显示违背原因组件
			vltShow: {
				type: Boolean,
				default:false,
			},
			// 差旅标准
			vltReason: {
				type: String,
				default:'',
			},
			// 传入业务数据 机票10901 酒店10903 火车票10904 
			byNumber: {
				type: [Number,String],
				default: 10904,
			},
			reasonCode:{
				type:[Number,String]
			},
			reasonDesc:{
				type:[String]
			},
			weiType:{
				type: [Number,String],
				default: 1,
			}
			
		}, 
		data() {
			return {
				reasonId:'QT', //违背差标id
				reasonList:[],  // 违背差标列表
				disabled:true, 
				qtValueObj:{ // 选择其他时输入的值
					reasonCode:'QT',
					reasonDesc:''
				},
				chooseReasonObj:{
                    reasonCode:'QT',
					reasonDesc:'',
                    weiType:1
                } ,// 选中违背原因
				visible: false,
				bx:false, //必选
				reason: '',
			}
		},
		watch: {
			vltShow: {
				handler(val){
					this.visible = val;
					if(val==true){
					    this.reasonId = this.reasonCode || 'QT';
                        if(this.reasonId == 'QT'){
                            this.qtValueObj.reasonDesc = this.reasonDesc;
                            this.disabled=false
                        }
                        this.chooseReasonObj.reasonCode = this.reasonCode || this.reasonId;
                        this.chooseReasonObj.reasonDesc = this.reasonDesc || '' ;
						this.queryReasonList()	
					}
				},
				immediate: true
			},
			vltReason: {
				handler(val){
					this.reason = val;
				},
				immediate: true
			}
			
		},
		mounted() {
			this.reasonId = this.reasonCode;
			if(this.reasonId == 'QT'){
				this.qtValueObj.reasonDesc = this.reasonDesc;
			}
			this.chooseReasonObj.reasonCode = this.reasonCode;
			this.chooseReasonObj.reasonDesc = this.reasonDesc;
			
		},
		methods: {
			// 查询违背原因
			queryReasonList() {	
				let obj = {
					parNo: 125,
					by1: this.byNumber
				}
				
				/**
				* obt老版 调用getBClass
				* h5 调用 violateReasonList
				* 现在调整为 violateReasonList
				* */ 
				this.$conn.getConn('commu.violateReasonList',{error:true})(obj).then((res)=>{
					let list = res.data || [];
                    let obj={
                        reasonCode:'QT',
                        reasonDesc:'',
                    }
                    list.push(obj)
					this.reasonList = this.$common.deepCopy(list)
				})
			},
			// 选中违背差标ID
			chooseReasonId(el,item) {
				if(el.target.tagName == 'INPUT'){
					return 
				}
				this.reasonId = item.reasonCode;
				this.chooseReasonObj = item;
				this.bx = false;
				if(this.reasonId!='QT'){
					this.disabled = true;
					this.qtValueObj.reasonDesc = '';
				}else{
					this.disabled = false;	
					this.chooseReasonObj = this.qtValueObj;
				}	
			},
			// 确认违背原因，返回数据
			chooseReason() {
				this.chooseReasonObj.weiType = this.weiType;
				if(this.chooseReasonObj.reasonCode != 'QT'){
					this.$emit('chooseReason',this.chooseReasonObj)
				}else if(this.chooseReasonObj.reasonCode == 'QT'){
					if(!this.qtValueObj.reasonDesc){
						this.bx = true;
						return 
					}else{
						this.bx = false;
                        this.chooseReasonObj.reasonDesc=this.qtValueObj.reasonDesc					
					}

					this.$emit('chooseReason',this.chooseReasonObj)
				}else{
					this.bx = true;
				}
			},
			// 关闭弹框
			handleClose() {
				this.chooseReasonObj = {} ;
				this.bx = false;
				this.$emit('cancelReason',false)
			},
			 
			
		}
	}
</script>

<style lang="scss" scoped >
	
	.vlt-main{
		
		.vlt-tit{
			text-align: left;
		}
		
		.vlt-con{
			margin: 20px 0;
			max-height: 200px;
			overflow-y: auto;
			border: 1px solid #ccc;
			padding: 20px;
			.vlt-w{
				width: 100%;
			}
			.vlt-line{
				line-height: 30px;
			}
			.vlt-obs{
				display: flex;
				justify-content: flex-start;
				.vlt-input-w{
					width: 40%;
				}
			}
		}
		.vlt-p{
			p{
				line-height: 28px;
			}
			.cl-red{
				color:red;
			}
		}
	}
	.vlt-tit ::v-deep{
		.el-dialog__body{
				padding: 10px 30px;
			}
	}
	.color-red{
		color:red;
	}
</style>
