

//  全局过滤器


import dateTime from './../dateTime.js'


/**
 *  折扣过滤  filterDiscount
 * @param val {Number, String} 转换的字符串对象
 * @return {String}
 *
 */ 

var  filterDiscount = function(val){
	if(!val){
		return val
	}
	let num = (val/10).toFixed(2) ;
	
	if(num == 10){
		return '全价'
	}else if(num > 10){
		return (num/10).toFixed(1) + '倍'
	}else{
		return num + '折'
	}
}

/**
 *  yyyy-mm-dd  转换为  mm月dd日
 * @param  {String} val
 * @return {String}
*/ 
var filterMDformat = function(val){
	if(val){
	  return dateTime.MDFormat(val)
	}else{
		return ''
	}
}
/**
 *  yyyy-mm-dd  转换为 周几
 * @param  {String} val
 * @return {String}
*/ 
var filterWeekformat = function(val){
	if(val){
	  return dateTime.getWeekZhou(val)
	}else{
		return ''
	}
}

//过滤身份证件信息
var filterIdNo = function(val){
  var str = '';
  if(val && val.length == 18) {
  	str = val.substr(0, 4) + '********' + val.substr(val.length - 2, 2);
  	return str;
  } else if(val) {
  	str = val.substr(0, 4) + '********' + val.substr(val.length - 2, 2);
  	return str;
  } else {
  	return '';
  }
}

// 过滤手机号 
var filterPhone = function(val){
  var str = '';
  if(val && val.length == 11) {
  	str = val.substr(0, 3) + '****' + val.substr(val.length - 4, 4);
  	return str;
  } else if(val) {
  	str = val.substr(0, 3) + '****' + val.substr(val.length - 4, 4);
  	return str;
  } else {
  	return '';
  }
}

// 过滤护照
var filterPassPort = function(val){
  var str = '';
  if(val && val.length == 9) {
  	str = val.substr(0, 2) + '****' + val.substr(val.length - 2, 2);
  	return str;
  } else if(val) {
  	str = val.substr(0, 3) + '****' + val.substr(val.length - 2, 2);
  	return str;
  } else {
  	return '';
  }
}

// 处理行李额问题
var filterBaggage = function(val){
	
	return val;
	
	// let str = '';
	// val = val || '';
	// if(!val){
	// 	return ''
	// }
	// if(val.indexOf('k')>-1 || val.indexOf('K')>-1){
	// 	str = val.replace(/k|kg|K|KG/,'kg')
	// }else if(val.indexOf('pc')>-1){
	// 	str = val
	// }else{
	// 	str = `${val}`
	// }
	// return str
}
 


export {filterDiscount,filterMDformat,filterWeekformat,filterIdNo,filterPhone,filterPassPort,filterBaggage}

