<template>
	<div>
		<!-- type   1 国内城市 2 商圈-->
		<el-popover
			v-if="type == 1"
			placement="bottom-start"
			width="500"
			popper-class="pop-ht-city"
			ref="popCity"
			@show="showPopverCity"
			@hide="hidePopverCity"
			v-model="cityVisible"
			:close-delay="0"
			trigger="click"
		>
			<div v-if="!searchCityBool">
				<el-tabs type="border-card">
					<el-tab-pane
						v-for="(item, index) in htCityList"
						:key="index"
						:label="item.titleName"
					>
						<div v-if="item.content.length > 0">
							<div class="city-ht">
								<div
									class="city-ht-item"
									v-for="(v, i) in item.content"
									:key="i"
								>
									<div
										v-show="index != 0"
										style="
											margin-right: 10px;
											width: 30px;
											color: #409EFF
										"
									>
										{{ v.name }}
									</div>
									<div>
										<span
											style="position: relative"
											v-for="(vSub, iSub) in v.value"
											:key="iSub"
											@mouseenter="enter(i, iSub)"
											@mouseleave="leave(i, iSub)"
											@click="checkBtn(vSub, type)"
										>
											<span class="city-ht-btn">
												{{ vSub.cityName }}
												<span
													class="city-ht-span"
													v-show="
														activeIndexSub ==
															iSub &&
														activeIndex == i
													"
													>{{ vSub.cityName }}</span
												>
											</span>
										</span>
									</div>
								</div>
							</div>
						</div>
						<div v-else>暂无数据</div>
					</el-tab-pane>
				</el-tabs>
			</div>
			<div v-else>
				<div>可输入中文/全拼/简拼/城市名称</div>
				<div
					class="search-ht-city-result-content"
					v-if="searchCityListResultCurrent.length > 0"
				>
					<p
						v-for="(item, index) in searchCityListResultCurrent"
						:key="index"
						:class="[
							'search-ht-city-result',
							index == 0 ? 'red' : '',
						]"
						@click="checkBtnSearch(item, type)"
					>
						<span>{{ item[1] }}</span>
						<span>{{ item[4] }}</span>
					</p>
					<yj-pagination
						:small="true"
						paStyle="margin: 20px 0 50px 0;"
						@handleCurrentChange="handleCurrentChange"
						:total="searchCityListResult.length"
						:currentPage="currentPageCity"
					></yj-pagination>
				</div>
				<div class="search-ht-city-result-content" v-else>暂无数据</div>
			</div>
			<el-input
				size="large"
                prefix-icon="iconfont yj-icon-jiudian1"
				:style="{width:inputWidth+'px'}"
				placeholder="请输入城市"
				v-model.lazy="ccsqdCityName"
				@input.native="inputCityEvent"
				:disabled="disabled"
				slot="reference"
			>
				<!-- <i slot="prefix" class="iconfont yj-icon-jiudian1"></i> -->
			</el-input>
		</el-popover>
 
		<el-popover
			v-else-if="type == 2"
			placement="bottom-start"
			width="500"
			popper-class="pop-ht-city"
			ref="popCityGeo"
			@show="showPopver"
			@hide="hidePopver"
			v-model="visible"
			:close-delay="0"
			trigger="click"
		>
			<div v-if="isShow">
				<div v-if="geoLocationList.length > 0">
					<el-tabs type="border-card">
						<el-tab-pane
							v-for="(item, index) in geoLocationList"
							:key="index"
							:label="item.typeName"
						>
							<div v-if="item.location.length > 0">
								<div class="city-ht">
									<span
										style="position: relative"
										v-for="(vSub, iSub) in item.location"
										:key="iSub"
										@mouseenter="enter(0, iSub)"
										@mouseleave="leave(0, iSub)"
										@click="checkBtn(vSub, type)"
									>
										<span class="city-ht-btn">
											{{ vSub.name }}
											<span
												class="city-ht-span"
												v-show="activeIndexSub == iSub"
												>{{ vSub.name }}</span
											>
										</span>
									</span>
								</div>
							</div>
							<div v-else>暂无数据</div>
						</el-tab-pane>
					</el-tabs>
				</div>
				<div v-else>暂无数据</div>
			</div>
			<div v-else>
				<div>可输入中文/拼音,多个用空格隔开</div>
				<div
					class="search-ht-city-result-content scroll-geo"
					v-if="!!searchCityGeoListResult && searchCityGeoListResult.length > 0"
				>
					<p
						v-for="(item, index) in searchCityGeoListResult"
						:key="index"
						:class="[
							'search-ht-city-result',
							index == 0 ? 'red' : '',
						]"
						@click="checkBtnSearch(item, type)"
					>
						<span>{{ item.name }}</span>
						<span>{{ item.poiType==3?"地标":item.poiType==4?'酒店':'行政区' }}</span>
					</p>
				</div>
				<div class="search-ht-city-result-content" v-else>暂无数据</div>
			</div>
			<el-input

				size="large"
				style="width: 200px"
				:disabled="!cityId"
				placeholder="酒店、位置等关键词"
				v-model="keywords"
				@input.native="inputCityGeoEvent"
				slot="reference"
			>
				<i slot="prefix" class="el-input__icon el-icon-search"></i>
			</el-input>
		</el-popover>
	</div>
</template>

<script>
const isasc = /^[\!-~]+/ //是否为字母数字
import { mapState, mapMutations, mapGetters } from 'vuex'
import { throttle } from '@/plugins/utils';
export default {
	name: 'HtCity',
	props: {
		// 1 国内城市 2 商圈
		type: {
			type: Number,
			default: 1,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: true,
		},
		inputWidth:{
			type: Number,
			default: 200,
		},
		isModalCity:{
			type: Boolean,
			default: true,
		}
	},
	data() {
		return {
			currentPageCity: 1,
            isShow:true,
			activeIndex: -1,
			activeIndexSub: -1,
            cityVisible:false,
            CityGeoVisible:false,
			visible: false,
			inputCity: '',
			// keywords: '',
			geoLocationList: [],
			htCityList: [
				{
					title: 'HOT',
					titleName: '热门',
					content: [],
				},
				{
					title: 'ABCDEF',
					titleName: 'ABCDEF',
					content: [],
				},
				{
					title: 'GHJ',
					titleName: 'GHJ',
					content: [],
				},
				{
					title: 'KLMN',
					titleName: 'KLMN',
					content: [],
				},
				{
					title: 'PQRSTW',
					titleName: 'PQRSTW',
					content: [],
				},
				{
					title: 'XYZ',
					titleName: 'XYZ',
					content: [],
				},
			],
			searchCityList: [],
			searchCityListResult: [], // 查询后的城市数据
			searchCityListResultCurrent: [], // 查询后的城市数据分页
			searchCityBool: false,
			searchCityGeoBool: false,
			timeStampCity: 0,
			timeStampCityGeo: 0,
			searchCityGeoListResult: [], // 查询后的商圈数据
			cityChangeNum: 0,
		}
	},
	computed: {
        //  优化，拿到cityId直接回显操作
		...mapState({
			htCnQuery: (state) => state.htCn.htCnQuery, //
			htCnCcsqD: (state) => state.htCn.htCnCcsqD, //
			htCnParams:(state) => state.htCn.htCnParams, //
            htCnPolicy:(state) => state.htCn.htCnPolicy, //
		}),
		tripType() {
			/* 默认当前因公因私*/
			return this.htCnQuery.tripType
		},
        cityId: {
			get() {
				return this.htCnParams.cityId
			},
			set(val) {
				this.SET_HTCN_PARAMS({ cityId: val })
			},
		},
        keywords: {
			get() {
            
				return this.htCnParams.keywords
			},
			set(val) {
				this.SET_HTCN_PARAMS({ keywords: val })
			},
		},
		bookStyle() {
			// 1单订模式  2出差申请单模式
			let bookStyle =this.$common.sessionget('user_info').vipCorp.bookStyle
			return bookStyle
		},
		changeRouteRole() {
			return this.htCnQuery.changeRouteRole
		},
        ccsqdCityName: {
			get() {
				return this.htCnParams.ccsqdCityName
			},
			set(val) {
				this.SET_HTCN_PARAMS({ ccsqdCityName: val })
			},
		},
	},
	mounted() {
		if (this.type == 1) {
			this.getCity()
		}
	
		// 因私 因公都要 获取城市数据
		// if(this.tripType == 1 && this.bookStyle == 2){
		// 	this.getCity()
		// }
		
		// 调用一次就够了 因为要用商圈输入，this.type == 2时调用
		if(this.type == 2 && this.htCnParams?.cityId){
      this.getCityGeo()
		}
	},
	watch: {
		cityId: {
			handler(value,oldvalue) {
				if(this.type == 2 ){
					this.getCityGeo()
				}
				this.cityChangeNum += 1;
                if(value!=oldvalue && this.cityChangeNum >= 2){
                    this.keywords=''
                }
			},
			immediate: true,
			deep:true
		},
        keywords(val){ //关键字为空的时候清空商圈关键字搜索结果,同时需要清空搜索参数
            if(!val){
                this.isShow=true
                this.searchCityGeoListResult=[]
                this.searchCityGeoBool=true
                this.SET_HTCN_PARAMS({
                    searchType:0,
                    businessZone:'',
                    district:'',
                    keywords:'',
                    lat:'',
			        lon:'',
                })

            }else{
                this.isShow=false
            }
        },
		// 此处不需要 查询城市名称调用 getCityGeo
		// ccsqdCityName: { //
		// 	handler(value) {
		// 		//当为因公模式下且为出差申请单模式下 inputCity==ccsqdCityName
		// 		if (this.tripType == 1 && this.bookStyle == 2) {
		// 			this.getCityGeo()
		// 		}
		// 	},
		// },
		
	},
	methods: {
        ...mapMutations({
			SET_HTCN_POLICY: 'htCn/SET_HTCN_POLICY', // 保存差旅标准
            SET_HTCN_PARAMS:'htCn/SET_HTCN_PARAMS'
		}),
		handleCurrentChange(val) {
			this.searchCityListResultCurrent = []
			this.searchCityListResult.map((v, i) => {
				if (i < val * 10 && i >= (val - 1) * 10) {
					this.searchCityListResultCurrent.push(v)
				}
			})
		},
		// 商圈关键字查询
		inputCityGeoEvent(e) {
			let term = e.target.value
			if (!term) {
				this.searchCityGeoBool = false
                let obj ={

                }
                this.$emit('checkHtCity', obj)
				return
			}
			this.timeStampCityGeo = e.timeStamp
			setTimeout(() => {
				if (this.timeStampCityGeo - e.timeStamp === 0) {
					this.$refs.popCityGeo.doShow()
					this.$conn
						.getConn('staticData.poiPlace', { jsfile: true })({
							cityId: this.cityId,
							cityName: this.ccsqdCityName,
							keywords: term,
							cplx: 10903,
						})
						.then((res) => {
							if (res) {
								this.searchCityGeoListResult = res.data
							} else {
								this.searchCityGeoListResult = []
							}
							this.searchCityGeoBool = true
						})
				}
			}, 500)
		},
		// 城市关键字查询
		inputCityEvent(e) {
			let term = e.target.value
           
			if (!term) {
				this.searchCityBool = false
				return
			}
			this.timeStampCity = e.timeStamp
			setTimeout(() => {
				if (this.timeStampCity - e.timeStamp === 0) {
					this.$refs.popCity.doShow()
					if (/^[0-9]/.test(term)) {
						this.searchCityListResult = []
					}
					var useasc = isasc.test(term)
					this.searchCityListResult = []
					let mather = new RegExp('^' + term, 'i')
					for (var cityindex in this.searchCityList) {
						var city = this.searchCityList[cityindex]
						if (useasc) {
							//是拼音开头
							if (mather.test(city[3]) || mather.test(city[2])) {
								this.searchCityListResult.push(city)
							}
						} else {
							if (mather.test(city[1])) {
								this.searchCityListResult.push(city)
							}
						}
					}
					this.searchCityBool = true
					this.searchCityListResultCurrent = []
					this.searchCityListResult.map((v, i) => {
						if (i < 10) {
							this.searchCityListResultCurrent.push(v)
						}
					})
				}
			}, 500)
		},
		// 内容移入事件
		enter(i, iSub) {
			this.activeIndex = i
			this.activeIndexSub = iSub
		},
		// 内容移出事件
		leave(i) {
			this.activeIndex = -1
			this.activeIndexSub = -1
		},
		// 获取城市数据
		getCity() {
			this.$conn
				.getConn('staticData.bcity', { jsfile: true })()
				.then((res) => {
                    // 去掉县等没有必要展示的
                    let myObj=res
            			for (let i in myObj) {
                          let myself=  myObj[i].filter(e=>{
                                return e.cityType!==4
                            })
                          myObj[i]=myself
					}
                    
					let obj = myObj
					// 查询数据构成
					this.searchCityList = []
					let searchList = []
					for (let i in obj) {
						if (i != 'HOT') {
							searchList.push(...obj[i])
						}
					}
					searchList.map((item) => {
						let arr = [
							item['id'],
							item['cityName'],
							item['pyjsm'],
							item['fullspell'],
							item['province'],
						]
						this.searchCityList.push(arr)
					})
					// 点选数据构成
					this.htCityList.map((item, index) => {
						let arr = []
						if (index == 0) {
							arr.push({
								name: 'HOT',
								value: obj['HOT'],
							})
						} else {
							for (let key in obj) {
								if (item.title.indexOf(key) != -1) {
									let arrObj = {
										name: key,
										value: obj[key],
									}
									arr.push(arrObj)
								}
							}
						}
						this.$set(this.htCityList[index], 'content', arr)
					})
				})
                          // 因公模式下匹配差旅标准
 
		},
		// 获取商圈数据
		getCityGeo:throttle(function(){
      this.$conn.getConn('staticData.geoLocation')({
      		cityId: this.cityId,
      		type: '1,2',
      	},(res) => {
      		let data = res.data || []
      		this.geoLocationList = data;
      		
      		this.geoLocationList.map((item) => {
      			if (item.type == '1') {
      				item.typeName = '行政区'
      			} else if (item.type == '2') {
      				item.typeName = '商圈'
      			}
      		})

					this.geoLocationList = this.geoLocationList.filter(item => item.type != 2);
      	})
    },1000),
		// 内容点击事件
		checkBtn(val, type) {
			// 1 城市  2 商圈
			if (type == 1) {
				this.ccsqdCityName = val.cityName
                this.cityVisible=false
				this.$emit('checkHtCity', val)
			} else {
				this.keywords = val.name
                this.CityGeoVisible=false
                let obj ={
                    timeStamp:new Date().getTime(),
                    target:{
                        value:val.name
                    }
            }
                this.inputCityGeoEvent(obj)
				this.$emit('checkHtCityGeo', val)
			}

		},
		// 关键字查询点击事件
		checkBtnSearch(val, type) {
			if (type == 1) {
				let obj = {
					id: val[0],
					cityName: val[1],
					pyjsm: val[2],
					fullspell: val[3],
					province: val[4],
				}
				this.ccsqdCityName = obj.cityName
				this.$emit('checkHtCity', obj)
				this.$refs.popCity.doClose()
			} else {
				this.keywords = val.name
				this.$emit('checkHtCityGeo', val)
				this.$refs.popCityGeo.doClose()
			}
		},
		// 监听弹框显示时的方法
		showPopverCity() {
            // 当城市被禁用的时候应该无法触发弹窗！
            // 什么情况下城市选择器无法出来？
            // 因公模式且为出差申请单模式下且changeRouteRole!=1的情况 ，changeRouteRole为2或3的时候才会触发有弹窗的出现！
            // if(this.tripType==1 && this.bookStyle==2 && this.changeRouteRole==0){
            //     this.cityVisible = false
            // }else if(this.tripType==1 && this.bookStyle==2 && this.changeRouteRole==1){
            //     this.cityVisible = true
            // }else if(this.tripType==1 && this.bookStyle==2 && (this.changeRouteRole==2 || this.changeRouteRole==3)){
            //     this.cityVisible = false
            //     this.$emit("changeRouteRole2")
            // }else{
            //     this.cityVisible = true
            // }
			if(this.isModalCity){
				this.cityVisible = true;
			}else if(this.isModalCity==false && this.changeRouteRole==0){
				 this.cityVisible = false
			}else if(this.isModalCity==false && this.changeRouteRole!=1){
				 this.cityVisible = false
				  this.$emit("changeRouteRole2")
			}
		},
		// 监听弹框隐藏时的方法
		hidePopverCity() {
			// 处理
			this.searchCityBool = false
		},
		// 监听弹框显示时的方法
		showPopver() {
			// 处理
			if (!this.cityId && this.type == 2) {
				this.$refs.popCityGeo.doClose()
				this.$message({
					type: 'error',
					message: '请先选择城市',
				})
			}
		},
		// 监听弹框隐藏时的方法
		hidePopver() {
			// 处理
		},
	},
}
</script>

<style scoped lang="scss">

.pop-ht-city {
	.city-ht {
		height: 400px;
		overflow-y: scroll;
		.city-ht-item {
			display: flex;
			justify-content: flex-start;
		}
		.city-ht-btn {
			display: inline-block;
			margin-bottom: 10px;
			padding-right: 10px;
			width: 74px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			cursor: pointer;
			.city-ht-span {
				// 鼠标移入的hover
				visibility: hidden;
				width: 80px;
				background-color: #409eff;
				color: #fff;
				text-align: center;
				border-radius: 6px;
				padding: 5px 0;
				position: absolute;
				z-index: 1;
				top: 80%;
				left: 80%;
				margin-left: -60px;
			}
			.city-ht-span::after {
				// 小箭头伪元素实现
				content: '';
				position: absolute;
				bottom: 100%;
				left: 50%;
				margin-left: -5px;
				border-width: 5px;
				border-style: solid;
				border-color: transparent transparent rgba(64, 158, 255, 1)
					transparent;
			}
		}
		.city-ht-btn:hover .city-ht-span {
			visibility: visible;
		}
	}
	.search-ht-city-result-content {
		:hover {
			background-color: #eee;
			cursor: pointer;
		}
		padding-top: 10px;
	}
	.scroll-geo {
		height: 400px;
		overflow-y: scroll;
	}
	.search-ht-city-result {
		display: flex;
		justify-content: space-between;
		padding: 10px;
	}
	.red {
		color: #f00;
	}
}
</style>
